import { useCallback, useEffect, useState } from "react";
import { getProductsCatalog } from "../services/userData";
import { patchProduct } from "../services/userUpdateData";
import { getCatalogProducts } from "../../shop/services/productService";

const useGeneralUpdate = (categoryName, phone, formData) => {
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]); 
  const [loadingProducts, setLoadingProducts] = useState(false);
  const tokenData = localStorage.getItem('accessToken');
  const { token } = JSON.parse(tokenData);
  const finalCategoryName = categoryName || formData.newName;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productData = await getProductsCatalog(phone);
        setAllProducts(productData || []);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [phone]);

  const fetchProductsFromCategory = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const { products: productArray } = await getCatalogProducts("aida_link", finalCategoryName, phone);
      setProducts(productArray); 
    } catch (error) {
      console.error("Failed to fetch category products:", error);
    } finally {
      setLoadingProducts(false);
    }
  }, [phone, finalCategoryName]);

  const addProductToSelection = (productId) => {
    const selectedProduct = allProducts.find(product => product.id === productId);
    if (selectedProduct && !selectedProducts.some(product => product.id === productId)) {
      setSelectedProducts([...selectedProducts, selectedProduct]);
    }
  };

  const removeProductFromSelection = (productId) => {
    setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
  };

  const submitUpdates = async () => {
    for (const product of selectedProducts) {
      const formDataToSend = new FormData();
      formDataToSend.append('userWhatsApp', phone);
      formDataToSend.append('catalog', 'aida_link');
      formDataToSend.append('id', product.id);
      formDataToSend.append('productName', product.productName || '');
      formDataToSend.append('productDescription', product.productDescription || '');
      formDataToSend.append('productPrice', product.productPrice);
      formDataToSend.append('productOfferPrice', product.productOfferPrice || '');
      formDataToSend.append('productCategory', finalCategoryName);
      formDataToSend.append('productInventory', product.productInventory || '');
      formDataToSend.append('productId', '');
      formDataToSend.append('size', product.size || '');
      formDataToSend.append('color', product.color || '');
      formDataToSend.append('status', product.status || 'true');
      formDataToSend.append('image_0', product.image_0 || '');
      formDataToSend.append('image_1', product.image_1 || '');
      formDataToSend.append('image_2', product.image_2 || '');
      formDataToSend.append('image_3', product.image_3 || '');
      formDataToSend.append('image_4', product.image_4 || '');

      try {
        const response = await patchProduct(token, formDataToSend);
        console.log("Product updated successfully:", response);
      } catch (error) {
        console.error("Failed to update product category:", error);
      }
    }
    // After submission, clear the selected products
    setSelectedProducts([]);
    fetchProductsFromCategory();
  };

  const deleteProductCategory = async (productId) => {
    const selectedProduct = allProducts.find(product => product.id === productId);

    if (!selectedProduct) {
      console.error("Product not found");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('userWhatsApp', phone);
    formDataToSend.append('catalog', 'aida_link');
    formDataToSend.append('id', selectedProduct.id);
    formDataToSend.append('productName', selectedProduct.productName || '');
    formDataToSend.append('productDescription', selectedProduct.productDescription || '');
    formDataToSend.append('productPrice', selectedProduct.productPrice || '');
    formDataToSend.append('productOfferPrice', selectedProduct.productOfferPrice);
    formDataToSend.append('productCategory', 'Feed'); 
    formDataToSend.append('productInventory', selectedProduct.productInventory || '');
    formDataToSend.append('productId', '');
    formDataToSend.append('size', selectedProduct.size || '');
    formDataToSend.append('color', selectedProduct.color || '');
    formDataToSend.append('status', selectedProduct.status || 'true');
    formDataToSend.append('image_0', selectedProduct.image_0 || '');
    formDataToSend.append('image_1', selectedProduct.image_1 || '');
    formDataToSend.append('image_2', selectedProduct.image_2 || '');
    formDataToSend.append('image_3', selectedProduct.image_3 || '');
    formDataToSend.append('image_4', selectedProduct.image_4 || '');

    try {
      const response = await patchProduct(token, formDataToSend);
      console.log("Product category deleted successfully:", response);
      
      fetchProductsFromCategory();
      
    } catch (error) {
      console.error("Failed to delete product category:", error);
    }
  };
  return { 
    allProducts, 
    addProductToSelection, 
    deleteProductCategory, 
    products, 
    loadingProducts, 
    fetchProductsFromCategory,
    selectedProducts, 
    removeProductFromSelection, 
    submitUpdates 
  };
};

export default useGeneralUpdate;
