import { useState } from 'react';

const usePopUpMessage = () => {
  const [popUp, setPopUp] = useState({ isVisible: false, message: '' });

  const handleSuccess = ( message = '¡Tu información de negocio se guardo correctamente!') => {
    setPopUp({
      isVisible: true,
      title: '¡Exito!',
      message,
    });
  };

  const handleFailure = (message = '¡Hubo un problema al intentar actualizar tu información! Intenta más tarde.') => {
    setPopUp({
      isVisible: true,
      title: 'Error',
      message,
    });
  };

  return {
    popUp,
    setPopUp,
    handleSuccess,
    handleFailure,
  };
};

export default usePopUpMessage;
