import styles from "../../SignUpForm.module.css";
import hidePasswordIcon from "../../assets/icons/hidePasswordIcon.svg";
import showPasswordIcon from "../../assets/icons/showPasswordIcon.svg";
import { usePersonalInfo } from "../../hooks/usePersonalInfo";
import InputField from "../../components/pages_components/InputField";
import AnimationButton from "../../components/utils/AnimationButton";

function PersonalInfoForm({ onPersonalInfoSubmit, phone }) {
  const {
    personalInfo, errors, validatedFields, handleChange, handleBlur,
    handleSubmit, togglePasswordVisibility, showPassword, isLoading,
    isSuccess
} = usePersonalInfo(phone,onPersonalInfoSubmit);
  
  return (
    <form onSubmit={handleSubmit}>
      <h2 className={styles.resourcesHeader}>Vamos a crear tu cuenta:</h2>
      <div className={styles.columnDocuments}>
        <div className={styles.formFieldInfo}>
          <InputField
            label="Correo electrónico"
            type="email"
            name="userEmail"
            placeholder="Ingresa tu correo"
            personalInfo={personalInfo}
            errors={errors}
            validatedFields={validatedFields}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
        <div className={styles.formFieldInfo}>
          <InputField
            label="WhatsApp"
            type="text"
            name="userWhatsApp"
            disabled={true}
            personalInfo={personalInfo}
            errors={errors}
          />
        </div>
        <div className={styles.formFieldInfo}>
          <InputField
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Ingresa una contraseña segura"
            personalInfo={personalInfo}
            errors={errors}
            validatedFields={validatedFields}
            handleChange={handleChange}
            handleBlur={handleBlur}
            renderAdditional={() => (
              <button type="button" onClick={togglePasswordVisibility} className={`${styles.togglePassword} ${showPassword ? styles.active : ""}`}>
                <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt={showPassword ? "Ocultar" : "Mostrar"} />
              </button>
            )}
          />
        </div>
        <div className={styles.formFieldInfo}>
          <InputField
            label="Confirmar contraseña"
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Ingresa tu contraseña nuevamente"
            personalInfo={personalInfo}
            errors={errors}
            validatedFields={validatedFields}
            handleChange={handleChange}
          />
        </div>
        <div className={styles.formFieldInfo}>
          <InputField
            label="¿Tienes código de referido?"
            type="text"
            name="registerCode"
            placeholder="Si alguien te invitó a aida pon su número de referido"
            personalInfo={personalInfo}
            errors={errors}
            validatedFields={validatedFields}
            handleChange={handleChange}
            handleBlur={handleBlur}
            required={false}
          />
        </div>
      </div>
      <AnimationButton isLoading={isLoading} isSuccess={isSuccess}>
        Confirmar
      </AnimationButton>
    </form>
  );
}

export default PersonalInfoForm;