import React, { useEffect, useRef } from "react";
import styles from "../../assets/styles/CatalogWelcome.module.css";
import Header from "../../components/SignUpForm/Header";
import Lottie from "lottie-web";
import confetti from '../../assets/animations/Welcome-Animation.json';

const CatalogWelcome = ({ name, onAccept, aidaPhone }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      const animationInstance = Lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: confetti,
      });

      return () => animationInstance.destroy();
    }
  }, []);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.animationContainer} ref={animationContainer}></div>
      <div className={styles.textContainer}>
        <header>
          <h1 className={styles.headerTitle}>¡Bienvenido! {name} </h1>
          <article className={styles.articleContent}>
            <p className={styles.finishText}>
              <span>¡Todo listo!</span> ¡Bienvenido a Aida! Ahora puedes
              gestionar tus pagos y crear o suscribirte a un catálogo, todo
              desde WhatsApp, de manera fácil y rápida
            </p>
          </article>
        </header>
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={() => onAccept()} className={styles.whatsappButton}>
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://api.whatsapp.com/send/?phone=${aidaPhone}&text=&type=phone_number&app_absent=0`}
          >
            {" "}
            Regresar a WhatsApp
          </a>
        </button>
      </div>
    </div>
  );
};

export default CatalogWelcome;
