import React from 'react'
import ContentLoader from 'react-content-loader'

export const ProductPageLoader = ({ props }) => {
  return (
    <ContentLoader viewBox="0 0 500 800" height="100%" width="100%" {...props} backgroundColor='#E8EAED' foregroundColor='#f5f5f5'>
        <rect x="3" y="3" rx="10" ry="10" width="500" height="450" />
        <circle cx="90%" cy="490" r="25" />
        <rect x="25" y="475" rx="0" ry="0" width="292" height="30" />
        <rect x="25" y="525" rx="0" ry="0" width="239" height="40" />
        <rect x="25" y="585" rx="0" ry="0" width="100" height="20" />
    </ContentLoader>
  )
}
