import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiry = () => {
      const tokenData = localStorage.getItem('accessToken');

      if (tokenData) {
        try {
          const { expiresAt } = JSON.parse(tokenData);

          const isExpired = new Date().getTime() > expiresAt;
          if (isExpired) {
            localStorage.removeItem('accessToken'); 
            navigate('/signin'); 
          }
        } catch (error) {
          console.error('Failed to parse token data:', error);
          localStorage.removeItem('accessToken');
          navigate('/signin');
        }
      }
    };

    checkTokenExpiry();

    // Optional: Set up a regular interval to check token expiry
    const interval = setInterval(checkTokenExpiry, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [navigate]);
};
