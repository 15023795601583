import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductList.module.css';
import RightArrow from '../../../assets/icons/rightArrow.svg';

const ProductList = ({ products, onDelete= () => {}, showDeleteButton = false, onProductClick=() => {} }) => {
  return (
    <ol className={styles.productList}>
      {products.map((product, index) => (
        <li
          key={index}
          className={`${styles.productItem} ${
            products.length === 1
              ? styles.singleProduct
              : index === 0
              ? styles.firstProduct
              : index === products.length - 1
              ? styles.lastProduct
              : styles.middleProduct
          }`}
        >
          <div className={styles.productContentWrapper}>
            <div className={styles.productImage}>
              {product.productImages && product.productImages.length > 0 ? (
                <img src={product.productImages[0]} alt={product.productName} />
              ) : (
                <div className={styles.noImage}></div>
              )}
            </div>
            <div className={styles.productContent}>
              <div className={styles.productDetails} onClick={() => onProductClick(product.id)}>
                <div className={styles.productInfo}>
                  <span className={styles.productName}>{product.productName}</span>
                  <span className={`${styles.productStock} ${product.active ? styles.inStock : styles.outOfStock}`}>
                    {product.productInventory === "Unlimited" ? "Ilimitado" : `${product.productInventory > 0 ? product.productInventory : '0'}`}
                  </span>
                </div>
                <div className={styles.productInfoPrice}>
                {product.productOfferPrice && product.productOfferPrice.trim() !== "" ? (
                  <>
                    <span className={styles.offerPrice}>${product.productOfferPrice} MXN</span>
                  </>
                ) : (
                  <span className={styles.productPrice}>${product.productPrice} MXN</span>
                )}
                </div>
              </div>
              {showDeleteButton ? (
                <button className={styles.deleteButton} type='button' onClick={() => onDelete(product.id)}>x</button>
              ) : (
                <button className={styles.linkButton} onClick={() => onProductClick(product.id)} type='button'><img src={RightArrow} alt="arrow" /></button>
              )}
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  onProductClick: PropTypes.func,
};

export default ProductList;
