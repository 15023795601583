import React from 'react';
import ContentLoader from 'react-content-loader';

const HeaderLoader = ({
  width = 420,
  heading = { width: 140, height: 24 },
  row = 2,
  column = 2,
  padding = 12,
  borderRadius = 15,
  marginBetweenRects = 10,
  buttonMargin = 20,
  buttonHeight = 35,
  showHeading = true, // New prop with default value
  ...props
}) => {
  const list = [];

  let height;

  for (let i = 1; i <= row; i++) {
    for (let j = 0; j < column; j++) {
      const itemWidth = (width - padding * (column + 1)) / column;

      const x = padding + j * (itemWidth + padding);

      const height1 = itemWidth;

      const height2 = 20;

      const height3 = 20;

      const space =
        padding + height1 + (padding / 2 + height2) + height3 + padding * 4;

      const y1 = padding + heading.height + padding * 2 + space * (i - 1);

      const y2 = y1 + padding + height1 + marginBetweenRects;

      const y3 = y2 + padding / 2 + height2 + marginBetweenRects;

      const y4 = y3 + padding / 2 + height3 + buttonMargin;

      list.push(
        <React.Fragment key={`${i}-${j}`}>
          <rect
            x={x}
            y={y1}
            rx={borderRadius}
            ry={borderRadius}
            width={itemWidth}
            height={height1}
          />
          <rect x={x} y={y2} rx={5} ry={5} width={itemWidth} height={height2} />
          <rect
            x={x}
            y={y3}
            rx={5}
            ry={5}
            width={itemWidth * 0.6}
            height={height3}
          />
          {/* Centered button with 90% width */}
          <rect
            x={x + itemWidth * 0.01} // Centered horizontally with 5% padding on each side
            y={y4}
            rx={15}
            ry={15}
            width={itemWidth} // 90% of the item card width
            height={buttonHeight}
          />
        </React.Fragment>
      );

      if (i === row) {
        height = y4 + buttonHeight;
      }
    }
  }

  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      {...props}
      backgroundColor='#E8EAED'
      foregroundColor='#f5f5f5'
    >
      {showHeading && heading && ( 
        <rect
          x={padding}
          y={padding}
          rx={5}
          ry={5}
          width={heading.width}
          height={heading.height}
        />
      )}
      {list}
    </ContentLoader>
  );
};

export default HeaderLoader;