import React, { useState, useEffect, useRef } from "react";
import styles from "../../assets/styles/CustomDropdown.module.css";

import { ReactComponent as EducacionIcon } from "../../assets/icons/educacion.svg";
import { ReactComponent as MedicosIcon } from "../../assets/icons/medicos.svg";
import { ReactComponent as RestaurantesIcon } from "../../assets/icons/restaurantes.svg";
import { ReactComponent as InmueblesIcon } from "../../assets/icons/inmuebles.svg";
import { ReactComponent as ServiciosIcon } from "../../assets/icons/servicios.svg";
import { ReactComponent as MiscelaneosIcon } from "../../assets/icons/miscelaneos.svg";
import { ReactComponent as AutosIcon } from "../../assets/icons/autos.svg";
import { ReactComponent as CatalogueraIcon } from "../../assets/icons/cataloguera.svg";

const CustomDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = {
    educacion: { label: "Educación", Icon: EducacionIcon },
    medicos: { label: "Medicos y dentistas", Icon: MedicosIcon },
    restaurantes: { label: "Restaurantes", Icon: RestaurantesIcon },
    inmuebles: { label: "Renta o venta de inmuebles", Icon: InmueblesIcon },
    servicios: { label: "Servicios y otros", Icon: ServiciosIcon },
    miscelaneos: {
      label: "Ventas al detalle/miscelaneos",
      Icon: MiscelaneosIcon,
    },
    autos: { label: "Venta de autos y refacciones", Icon: AutosIcon },
    cataloguera: { label: "Venta por catalogo", Icon: CatalogueraIcon },
  };
  const currentOption = options[value];
  const SelectedIcon = currentOption ? currentOption.Icon : null;

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleOptionClick = (optionKey, event) => {
    event.preventDefault();
    onChange(optionKey);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button
        type="button"
        onClick={toggleDropdown}
        className={styles.dropdownButton}
      >
        {SelectedIcon && (
          <>
            <SelectedIcon className={styles.icon} />
            {currentOption.label}
          </>
        )}
        {!SelectedIcon && "Seleccionar Industria"}
      </button>
      {isOpen && (
        <ul className={`${styles.dropdownList} ${isOpen ? styles.open : ""}`}>
          {Object.entries(options).map(([key, { label, Icon }]) => (
            <li
              key={key}
              onClick={(event) => handleOptionClick(key, event)}
              className={styles.dropdownItem}
            >
              <Icon className={styles.icon} />
              <span>{label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
