import styles from './SignUpForm.module.css';
import React, { useEffect, useState, useRef } from 'react';
import { fetchRegistrationStep } from './services/registrationService';
import { useFormData } from './hooks/useFormData';
import { useStepManager } from './hooks/useStepManager';
import { stepConfig } from './components/stepConfig';
import { useParamsContext } from './context/ParamsProvider';

const aidaPhone = process.env.REACT_APP_CHANNELS_CONTACT;

function SignUpForm() {
  const { phone } = useParamsContext();
  const [preferedName, setPreferedName] = useState('');
  const { handleFormData } = useFormData();
  const { step, setStep, nextStep, prevStep } = useStepManager('USER_INFO');
  const [showCreateCatalog, setShowCreateCatalog] = useState(false);
  const formContentRef = useRef(null);

  useEffect(() => {
    const initStep = async () => {
      try {
        const result = await fetchRegistrationStep(phone);
        setPreferedName(result.preferedName);
        setStep(result.step);
      } catch (error) {
        console.error('Problem fetching registration step:', error);
      }
    };
    initStep();
  }, [setStep, phone]);

  useEffect(() => {
    if (formContentRef.current) {
      formContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [step]);

  const stepComponents = stepConfig(
    handleFormData,
    nextStep,
    prevStep,
    phone,
    showCreateCatalog,
    setShowCreateCatalog,
    aidaPhone,
    preferedName,
    step
  );
  const CurrentStep = stepComponents[step] || stepComponents['default'];

  return (
    <div className={styles.formContainer}>
      <div className={styles.formContent} ref={formContentRef}>
        <CurrentStep />
      </div>
    </div>
  );
}

export default SignUpForm;
