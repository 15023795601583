import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import Spinner from "../../assets/animations/LoadinSpinner.json"
import Checkmark from "../../assets/animations/SuccessSpinner.json"
import styles from '../../assets/styles/AnimationButton.module.css'; 

const AnimationButton = ({ isLoading = false, isSuccess = false, children, ...props }) => {
    return (
        <div className={styles.continueButtonContainer}>
            <button type="submit" className={styles.continueButton} {...props}>
                {isLoading ? (
                    <Lottie animationData={Spinner} style={{ width: 22, height: 22 }} />
                ) : isSuccess ? (
                    <Lottie animationData={Checkmark} style={{ width: 22, height: 22 }} />
                ) : (
                    children
                )}
            </button>
        </div>
    );
};

AnimationButton.propTypes = {
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default AnimationButton;
