export const handleShare = (url) => {
  if (navigator.share && navigator.canShare({ url })) {
    navigator.share({
      title: 'Check out this link',
      url,
    }).catch((error) => console.log('Error sharing', error));
  } else {
    handleCopy(url);
    alert('Share not supported on this browser. The link has been copied to your clipboard.');
  }
};

export const handleCopy = (url) => {
  navigator.clipboard.writeText(url)
    .then(() => alert('Link copied to clipboard!'))
    .catch((err) => console.log('Failed to copy: ', err));
};
