import React from "react";
import styles from "../../assets/styles/AidaComponent.module.css";
import WhatsAppFrame from "../../assets/Images/WhatsAppFrame.svg";
import logo from "../../logo.svg";

const AidaComponent = ({ copy }) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>
          Hola, soy{" "}
          <img src={logo} alt="Catalogo Web Logo" className={styles.logo} />
        </h1>
        <p className={styles.subtitle}>La aliada de tu negocio.</p>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={WhatsAppFrame}
          alt="WhatsApp Logo"
          className={styles.whatsappLogo}
        />
      </div>
      <div className={styles.descriptionContainer}>
        <p className={styles.description}>{copy}</p>
      </div>
      <hr />
    </div>
  );
};

export default AidaComponent;
