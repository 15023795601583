const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API;

export const updateCartFromServer = async (phone, items, catalogParam, isPayment) => {
  try {
    console.log(phone,items,catalogParam, isPayment)
    const payload = { phone, products: items.map(item => ({ productId: item.productId, id: item.id, quantity: item.quantity })) };
    console.log(payload, "payload")
    const response = await fetch(`${apiUrl}/api/v1/products/shopping/${catalogParam}?payment_link=${isPayment}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    const { shoppingResponse } = data;
    const productIsWithPromo = shoppingResponse.products.map(item => ({
        quantity: item.quantity,
        totalAmount: item.totalAmount,
        productId: item.product.productId,
        OfferPrice: item.product.productOfferPrice,
        isProductInOffer: item.isProductInOffer
    }))
    const paymentLink = data.paymentLink
    console.log("Items from server", shoppingResponse)
    console.log("Items are with promo", productIsWithPromo)

    return {
      total: parseFloat(shoppingResponse.shoppingAmount).toFixed(2),
      products: productIsWithPromo,
      paymentLink: paymentLink
    };

  } catch (error) {
    console.error("Error fetching cart data:", error);
    return null;
  }
};
