import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/HomePage.module.css";
import ProductCarousel from "../Carousel/ProductCarousel";
import { ReactComponent as ViewAllIcon } from "../../../assets/icons/ViewAllIcon.svg";

const CategorySection = ({
  category,
  catalogParam,
  phone,
  categoryProducts,
  businessColor,
}) => {
  const products = categoryProducts[category] || [];

  if (
    category.toLowerCase() === "uncategorized" ||
    category.toLowerCase() === "sin categorizar" ||
    products.length === 0
  ) {
    return null;
  }

  return (
    <div className={styles.categorySection}>
      <div className={styles.categoryHeader}>
        {category.toLowerCase() !== "feed" && <h2>{category}</h2>}
        <Link
          className={styles.categoryButton}
          to={
            catalogParam
              ? `/shop/catalog/${catalogParam}/category/${category}`
              : `/shop/user/${phone}/category/${category}`
          }
        >
          Ver mas
          <ViewAllIcon className={styles.viewAllIcon} />
        </Link>
      </div>
      <ProductCarousel products={categoryProducts[category] || []} businessColor={businessColor} catalogParam={catalogParam}/>

      <div className={styles.categoryDivider}></div>
    </div>
  );
};

export default CategorySection;
