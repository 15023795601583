import React from 'react';
import styles from './ImageUploader.module.css';

const ImageUploader = ({ previewImages, handleImageChange }) => {
  const triggerFileInput = (id) => {
    document.getElementById(id).click();
  };

  return (
    <div className={styles.imageUploader}>
      <h3>Imagenes</h3>
      <div className={styles.imagesContainer}>
        <div className={styles.imageInputLarge} onClick={() => triggerFileInput('largeImageInput')}>
          {previewImages.large ? (
            <img src={previewImages.large} alt="Large Upload" className={styles.imagePreview} />
          ) : (
            <button type="button">+</button>
          )}
          <input
            type="file"
            id="largeImageInput"
            style={{ display: 'none' }}
            onChange={(e) => handleImageChange('large', e.target.files[0])}
          />
        </div>
        <div className={styles.imageInputColumn}>
          {['small1', 'small2'].map((key) => (
            <div
              key={key}
              className={styles.imageInputSmall}
              onClick={() => triggerFileInput(`${key}ImageInput`)}
            >
              {previewImages[key] ? (
                <img src={previewImages[key]} alt={`Small Upload ${key}`} className={styles.imagePreview} />
              ) : (
                <button type="button">+</button>
              )}
              <input
                type="file"
                id={`${key}ImageInput`}
                style={{ display: 'none' }}
                onChange={(e) => handleImageChange(key, e.target.files[0])}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
