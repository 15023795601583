import { useState, useEffect } from 'react';

const useProductSearch = (categories, categoryProducts) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategoryProducts, setFilteredCategoryProducts] = useState({});

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") 
            .replace(/[^\w\s]/gi, '') 
            .toLowerCase(); 
  };

  useEffect(() => {
    if (searchTerm) {
      const searchQuery = normalizeText(searchTerm);
      const newFilteredProducts = {};

      categories.forEach(category => {
        const filteredProducts = categoryProducts[category]?.filter(product => {
          const productName = normalizeText(product.productName);
          return productName.includes(searchQuery);
        }).map(product => ({
          ...product,
          productId: product.productId || product.id
        }));

        if (filteredProducts && filteredProducts.length > 0) {
          newFilteredProducts[category] = filteredProducts;
        }
      });

      setFilteredCategoryProducts(newFilteredProducts);
    } else {
      setFilteredCategoryProducts({});
    }
  }, [searchTerm, categories, categoryProducts]);

  return {
    searchTerm,
    setSearchTerm,
    filteredCategoryProducts,
  };
};

export default useProductSearch;