import React from "react";
import Header from "../components/Header/Header";
import styles from "../styles/HomePage.module.css";
import { useHomePageData } from "../hooks/pages_hooks/useHomePageData";
import CategorySection from "../components/CategorySection/CategorySection";
import ProductSearchComponent from "../components/Search/Search";
import CartToggle from "../components/Cart/CartToggle";
import { HomePageLoader } from "../components/Loaders/HomePage/HomePageLoader";
import CategoryList from "../components/CategorySection/CategoryList";

function HomePage({ phone, catalogParam }) {
  const { categories, categoryProducts, loading, error, businessInfo } =
    useHomePageData(phone, catalogParam);

  if (loading) return <HomePageLoader />;
  if (error) return <p>Error: {error}</p>;

  // Reorder the categories array to move "Feed" to the front
  const reorderedCategories = categories.sort((a, b) => {
    if (a === "Feed") return -1;
    if (b === "Feed") return 1;
    return 0;
  });

  return (
    <>
      <Header businessInfo={businessInfo} />
      <div className={styles.container}>
        <div className={styles.searchContainer}>
          <ProductSearchComponent
            categories={reorderedCategories}
            categoryProducts={categoryProducts}
          />
          <CartToggle phone={phone} catalogParam={catalogParam}/>
        </div>
        <div className={styles.searchComponents}>
          <CategoryList categories={reorderedCategories} phone={phone} catalogParam={catalogParam} />
        </div>
        <hr/>
        {reorderedCategories.map((category) => (
          <CategorySection
            category={category}
            categoryProducts={categoryProducts}
            catalogParam={catalogParam}
            phone={phone}
            key={category}
            businessColor={businessInfo.businessColor}
          />
        ))}
      </div>
    </>
  );
}

export default HomePage;
