import { useEffect, useState } from "react";
import { getProductDetails } from "../../services/productService";

export const useProductDetails = (productIdOrId, phone, catalogParam) => {
  console.log(productIdOrId)
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!productIdOrId) {
      setError("No product ID provided");
      setLoading(false);
      return;
    }

    setLoading(true);
    getProductDetails(productIdOrId, phone, catalogParam)
      .then((data) => {
        if (data) {
          setProduct(data);
        } else {
          setError("Product not found!");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("Failed to load product details");
        setLoading(false);
      });
  }, [productIdOrId, phone, catalogParam]);

  return { product, loading, error };
}