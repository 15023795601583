// UtilitiesFunctions.js

import {
  validateEmail,
  validatePassword,
  passwordsMatch,
  validateRegisterCode,
  validateImageFormat,
  isCsvFile,
  isValidUrl,
  validateMemberNumber,
  validateClabe,
  validateNonEmptyString,
  validateCsfFormat,
} from "./validators.js";

export const validatePersonalInfo = (personalInfo) => {
  const errors = {};

  if (personalInfo.userEmail !== undefined && !validateEmail(personalInfo.userEmail)) {
      errors.userEmail = "Correo electrónico invalido o ya esta en uso";
  }

  if (personalInfo.password !== undefined && !validatePassword(personalInfo.password)) {
      errors.password = "La contraseña debe contener al menos 6 letras";
  }

  if (personalInfo.confirmPassword !== undefined && !passwordsMatch(personalInfo.password, personalInfo.confirmPassword)) {
      errors.confirmPassword = "Las contraseñas no coinciden.";
  }

  if (personalInfo.registerCode !== undefined && !validateRegisterCode(personalInfo.registerCode)) {
      errors.registerCode = "Codigo de registro no valido.";
  }

  return errors;
};

export const validateBusinessInfo = (businessInfo) => {
  const errors = {};

  // Validate image format for businessLogo
  if (
    businessInfo.businessLogo &&
    !validateImageFormat(businessInfo.businessLogo.name)
  ) {
    errors.businessLogo = "Formato del logo no válido, deber ser PNG o JPG.";
  }

  // Validate image format for businessHeroBanner
  if (
    businessInfo.businessHeroBanner &&
    !validateImageFormat(businessInfo.businessHeroBanner.name)
  ) {
    errors.businessHeroBanner =
      "Formato del banner no válido, deber ser PNG, JPG o JPEG.";
  }

  // Ensure socialMediaLinks is an array before iterating
  if (Array.isArray(businessInfo.socialMediaLinks)) {
    businessInfo.socialMediaLinks.forEach((socialMediaLink) => {
      if (socialMediaLink.link && !isValidUrl(socialMediaLink.link)) {
        errors[
          `socialMedia.${socialMediaLink.type}`
        ] = `El enlace a ${socialMediaLink.type} no es una URL válida.`;
      }
    });
  } else {
    // Optionally handle or log an error or warning if socialMediaLinks is not an array
    console.warn("socialMediaLinks is not an array or is undefined.");
  }

  return errors;
};

export const validateProductUpload = (productUpload) => {
  const errors = {};

  // Ensure productImages is defined and is an array before iterating over it.
  if (
    Array.isArray(productUpload.productImages) &&
    productUpload.productImages.length > 0
  ) {
    productUpload.productImages.forEach((image, index) => {
      if (!validateImageFormat(image.name)) {
        errors[`productImage_${index}`] =
          "Invalid format; only PNG or JPG are allowed.";
      }
    });
  }

  return errors;
};

export const validateCsvFile = (file) => {
  const errors = {};
  if (!isCsvFile(file.name)) {
    errors.csvFile = "Invalid file type; only CSV files are allowed.";
  }
  return errors;
};

export const validateSubscriptionInfo = (subscriptionInfo) => {
  const errors = {};

  if (
    subscriptionInfo.memberNumber !== undefined &&
    !validateMemberNumber(subscriptionInfo.memberNumber)
  ) {
    errors.memberNumber = "Número de asociado no valido.";
  }

  return errors;
};

export const validateMoralInfo = (legalInfo) => {
  const errors = {};

  if (legalInfo.proofAddress && !validateImageFormat(legalInfo.proofAddress.name)) {
    errors.proofAddress = "Formato inválido para comprobante de domicilio.";
  }
  if (legalInfo.csf && !validateCsfFormat(legalInfo.csf.name)) {
    errors.csf = "Formato inválido para CSF.";
  }
  if (legalInfo.accountStatus && !validateCsfFormat(legalInfo.accountStatus.name)) {
    errors.accountStatus = "Formato inválido para Estado de Cuenta.";
  }
  if (legalInfo.constitutiveAct && !validateCsfFormat(legalInfo.constitutiveAct.name)) {
    errors.constitutiveAct = "Formato inválido para Acta Constitutiva.";
  }
  if (legalInfo.powersLegalRepresentative && !validateCsfFormat(legalInfo.powersLegalRepresentative.name)) {
    errors.accountStatus = "Formato inválido para Poderes del Representante Legal.";
  }
  for (let i = 1; i <= 5; i++) {
    const ineFrontField = `shareholdersIneFront${i}`;
    const ineBackField = `shareholdersIneBack${i}`;

    if (legalInfo[ineFrontField] && !validateImageFormat(legalInfo[ineFrontField].name)) {
      errors[ineFrontField] = "Formato inválido para INE delantera.";
    }
    if (legalInfo[ineBackField] && !validateImageFormat(legalInfo[ineBackField].name)) {
      errors[ineBackField] = "Formato inválido para INE trasera.";
    }
  }
  return errors;
};

export const validateLegalInfoLink = (legalInfo) => {
  const errors = {};

  // Validate CLABE
  if (legalInfo.clabe && !validateClabe(legalInfo.clabe.trim())) {
    errors.clabe = "La CLABE debe tener 18 dígitos.";
  }

  if (legalInfo.clabe !== legalInfo.confirmClabe) {
    errors.confirmClabe = "Los números CLABE no coinciden.";
  }

  // Validate bankName
  if (!legalInfo.bankName || !validateNonEmptyString(legalInfo.bankName)) {
    errors.bankName = "Debe seleccionar un banco.";
  }

  // Validate account owner name
  if (!legalInfo.ownerBankAccount || !validateNonEmptyString(legalInfo.ownerBankAccount)) {
    errors.ownerBankAccount = "El nombre del titular de la cuenta no puede estar vacío.";
  }

  // Validate ID Front and Back images
  if (!legalInfo.ineFront || !validateImageFormat(legalInfo.ineFront.name)) {
    errors.ineFront = "Formato inválido.";
  }
  if (!legalInfo.ineBack || !validateImageFormat(legalInfo.ineBack.name)) {
    errors.ineBack = "Formato inválido.";
  }

  return errors;
};