import React from 'react';
import styles from '../../assets/styles/SocialMediaList.module.css';

const SocialMediaList = ({ businessInfo, handleChange, validatedFields, errors }) => {
  return (
    <section className={styles.SocialMediaContainer}>
      <h4 className={styles.socialMediaHeader}>Redes sociales</h4>
      {businessInfo.socialMediaLinks.map((link, index) => (
        <label key={index} className={styles.socialMediaLinkLabel}>
          <input
            type="text"
            name={`socialMedia.${link.type}`}
            value={link.link}
            placeholder="Link de tu perfil"
            onChange={handleChange}
            className={`${styles.socialMediaLinkField} ${
              validatedFields[`socialMedia.${link.type}`] ? styles.socialMediaValidated : ""
            }`}
            data-social-media-type={link.type}
          />
          {errors[`socialMedia.${link.type}`] && (
            <div className="error">
              {errors[`socialMedia.${link.type}`]}
            </div>
          )}
        </label>
      ))}
    </section>
  );
};

export default SocialMediaList;
