import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { useProductDetails } from "../hooks/pages_hooks/useProductDetails";
import styles from "../styles/ProductPage.module.css";
import CartToggle from "../components/Cart/CartToggle";
import { ReactComponent as ReturnIcon } from "../../assets/icons/return-arrow.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-product.svg";
import { ProductPageLoader } from "../components/Loaders/ProductPage/ProducPageLoader";


function ProductPage({ phone, catalogParam }) {
  const navigate = useNavigate();
  const { productIdOrId } = useParams();
  const { product, loading, error } = useProductDetails(productIdOrId, phone, catalogParam);
  console.log(product)
  const { dispatch, state } = useContext(CartContext);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    if (product && product.productImages && product.productImages.length > 0) {
      setCurrentImage(product.productImages[0]);
    }
  }, [product]);

  if (loading) {
    return <ProductPageLoader />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleThumbnailHover = (image) => {
    setCurrentImage(image);
  };

  const cartItem = state.items.find((item) => item.id === product.id);

  const updatedOfferInstruction = Array.isArray(product.productOfferInstruction) && product.productOfferInstruction.length > 0
    ? product.productOfferInstruction[0].replace(
        'Al comprar 1 producto marcado así: <span class="icon-home"></span>',
        'Al comprar 1 producto marcado con casita'
      )
    : product.productOfferInstruction;

  return (
    <div className={styles.productDetailPageContainer}>
      <div className={styles.menuContainer}>
        <CartToggle phone={phone} catalogParam={catalogParam}/>
        <button onClick={() => navigate(-1)} className={styles.returnButton}>
          <ReturnIcon />
        </button>
      </div>
      <div className={styles.productDetailPage}>
        <section className={styles.productImagesSection}>
          {product?.productImages?.length > 1 && (
            <div className={styles.thumbnailContainer}>
              {product.productImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className={styles.thumbnail}
                  onMouseEnter={() => handleThumbnailHover(image)}
                />
              ))}
            </div>
          )}
          <div className={styles.mainImageContainer}>
            <img
              src={currentImage || "https://via.placeholder.com/150"}
              alt={product.productName || "Default Product"}
            />
          </div>
        </section>
        <section className={styles.productPurchaseSection}>
          <div className={styles.productTitleContainer}>
            <h1 className={styles.productName}>{product?.productName}</h1>
            <div className={styles.buttonContainer}>
              {cartItem && cartItem.quantity > 0 && (
                <button
                  onClick={() =>
                    dispatch({ type: "REMOVE_ITEM", payload: product })
                  }
                  className={styles.removeFromCartButton}
                >
                  <RemoveIcon />
                </button>
              )}
              <button
                onClick={() => dispatch({ type: "ADD_ITEM", payload: product })}
                className={styles.addToCartButton}
              >
                <AddIcon />
              </button>
            </div>
          </div>
          <article>
            <div className={styles.priceContainer}>
              {product?.productOfferPrice ? (
                <>
                  <h3 className={styles.productOfferPrice}>
                    ${product.productOfferPrice}
                    <span className={styles.currency}> MXN</span>
                  </h3>
                  <p className={styles.productRegularPrice}>
                    ${product.productPrice}
                  </p>
                </>
              ) : (
                <h3 className={styles.productPrice}>
                  ${product?.productPrice}
                  <span className={styles.currency}> MXN</span>
                </h3>
              )}
            </div>
            {product?.productOfferPrice && (
              <aside className={styles.productOfferDetails}>
                <h4>{product?.productOfferLabel}</h4>
                <p>{updatedOfferInstruction}</p>
              </aside>
            )}
          </article>
          <div>
            <p className={styles.productDescription}>
              {product?.productDescription}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ProductPage;