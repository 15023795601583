import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './NavBar.module.css';
import { capitalizeFirstLetters } from '../../utils/userUtils.js';
import BusinessIcon from '../../../assets/icons/business-icon.svg';
import HomeIcon from '../../../assets/icons/home-icon.svg';
import ShopIcon from '../../../assets/icons/shop-icon.svg';
import ProductsIcon from '../../../assets/icons/products-icon.svg';
import CategoriesIcon from '../../../assets/icons/categorys-icon.svg'
import ArrowIcon from '../../../assets/icons/arrow-icon.svg'
import signOutIcon from '../../../assets/icons/signout-icon.svg'

const NavBar = ({ userNameData, phone }) => {
  const location = useLocation();
  
  const [dropdowns, setDropdowns] = useState({
    categoryList: false,
    productServices: false,
    digitalShop: false,
  });

  const [dropdownSignOut, setDropdownSignOut] = useState(false);

  const toggleDropdown = (menu) => {
    setDropdowns((prevState) => {
      const isSameRoute = prevState[menu] && location.pathname.includes(menu);
      return {
        ...prevState,
        [menu]: isSameRoute ? false : !prevState[menu],
      };
    });
  };

  const toggleSignOut = () => {
    setDropdownSignOut((prevState) => !prevState);
  };

  const formattedName =
    userNameData && userNameData.firstNameLastName
      ? capitalizeFirstLetters(userNameData.firstNameLastName)
      : '';

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    window.location.href = `/signin?phone=${phone}`;
  };

  useEffect(() => {
    const tokenData = localStorage.getItem('accessToken');
    if (tokenData) {
      const { expiresAt } = JSON.parse(tokenData);
      const currentTime = new Date().getTime();
      const timeUntilExpiration = expiresAt - currentTime;

      if (timeUntilExpiration <= 0) {
        handleLogout();
      } else {
        const timeout = setTimeout(handleLogout, timeUntilExpiration);
        return () => clearTimeout(timeout);
      }
    }
  });

   useEffect(() => {
    const currentPath = location.pathname;
    setDropdowns((prevState) => ({
      categoryList: currentPath.includes('/dashboard/categorylist') || currentPath.includes('/dashboard/category'),
      productServices: currentPath.includes('/dashboard/products-services') || currentPath.includes('/dashboard/product-upload'),
      digitalShop: currentPath.includes('/dashboard/services') || currentPath.includes('/dashboard/shop'),
    }));
  }, [location.pathname]);

  return (
    <>
      <div className={styles.topBar}>
        <h1 className={styles.logo}>aida</h1>
        <div className={styles.logoSignOut} onClick={toggleSignOut}>
          <img src={userNameData.businessLogo} alt='logo'/>
          <p>{userNameData.businessName}</p>
          {dropdownSignOut && (
            <h3 className={styles.signOut} onClick={handleLogout}>SignOut <img alt='Sign Out' src={signOutIcon}/> </h3>
          )}
        </div>
      </div>
      <div className={styles.navbar}>
        <div className={styles.navContent}>
          <article className={styles.userData}>
            <div className={styles.avatar}>
              <img
                src={userNameData.businessLogo}
                alt="User Avatar"
                className={styles.avatar}
              />
            </div>
            <div className={styles.info}>
              <p className={styles.userName}>{formattedName}</p>
              <p className={styles.businessName}>
                {userNameData ? userNameData.businessName : ''}
              </p>
            </div>
          </article>
          <nav>
            <NavLink
              to="/dashboard/settings"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navItem} ${styles.active}`
                  : styles.navItem
              }
            >
              <img src={HomeIcon} alt="Inicio Icon" className={styles.icon} />
              Inicio
            </NavLink>
            <NavLink
              to="/dashboard/my-bussines"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navItem} ${styles.active}`
                  : styles.navItem
              }
            >
              <img
                src={BusinessIcon}
                alt="Mi negocio Icon"
                className={styles.icon}
              />
              Negocio
            </NavLink>
            <NavLink
              to="/dashboard/services"
              onClick={() => toggleDropdown('digitalShop')}
              className={({ isActive }) =>
                isActive
                  ? `${styles.navItem} ${styles.active}`
                  : styles.navItem
              }
            >
              <img src={ShopIcon} alt="Mis Tiendas Icon" className={styles.icon} />
              Tiendas Digitales
            </NavLink>
            {dropdowns.digitalShop && (
              <div className={styles.dropdownMenu}>
                <div className={styles.dropdownItemWrapper}>
                  <NavLink
                    to="/dashboard/shop"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.dropdownItem} ${styles.active}`
                        : styles.dropdownItem
                    }
                  >
                    Configura tu tienda
                  </NavLink>
                  <img
                    src={ArrowIcon}
                    alt="Mis productos Icon"
                    className={`${styles.arrowIcon}`}
                  />
                </div>
              </div>
            )}
            <NavLink
              to="/dashboard/products-services"
              onClick={() => toggleDropdown('productServices')}
              className={({ isActive }) =>
                isActive
                  ? `${styles.navItem} ${styles.active}`
                  : styles.navItem
              }
            >
              <img
                src={ProductsIcon}
                alt="Mis productos Icon"
                className={styles.icon}
              />
              Productos/servicios
            </NavLink>
            {dropdowns.productServices && (
              <div className={styles.dropdownMenu}>
                <div className={styles.dropdownItemWrapper}>
                  <NavLink
                    to="/dashboard/product-upload/"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.dropdownItem} ${styles.active}`
                        : styles.dropdownItem
                    }
                  >
                    Subir producto
                  </NavLink>
                  <img
                    src={ArrowIcon}
                    alt="Mis productos Icon"
                    className={`${styles.arrowIcon}`}
                  />
                </div>
              </div>
            )}
            <NavLink
              to="/dashboard/categorylist"
              onClick={() => toggleDropdown('categoryList')}
              className={({ isActive }) =>
                isActive
                  ? `${styles.navItem} ${styles.active}`
                  : styles.navItem
              }
            >
              <img src={CategoriesIcon} alt="Categoria Icon" className={styles.icon} />
              Categorías
            </NavLink>            
            {dropdowns.categoryList && (
              <div className={styles.dropdownMenu}>
                <div className={styles.dropdownItemWrapper}>
                  <NavLink
                    to="/dashboard/category"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.dropdownItem} ${styles.active}`
                        : styles.dropdownItem
                    }
                  >
                    Subir categoría
                  </NavLink>
                  <img
                    src={ArrowIcon}
                    alt="Mis productos Icon"
                    className={`${styles.arrowIcon}`}
                  />
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default NavBar;
