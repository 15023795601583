import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/UserProducts.module.css';
import ProductList from '../../components/navigation/ProductList.js';
import { getProductsCatalog } from '../../services/userData.js';
import { SearchBar } from '../../components/utils/SearchBar.js';
import { uploadProductCSV } from '../../services/userUpdateData.js';
import ImportIcon from "../../../assets/icons/import-icon.svg"
import dotsIcon from "../../../assets/icons/dots-icon.svg"

const UserProducts = ({ phone }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const fileInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productData = await getProductsCatalog(phone);
        setProducts(productData || []); 
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [phone]);

  const filteredProducts = products
    .filter((product) =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a.active === b.active) return 0; 
      return a.active ? -1 : 1; 
    });

  const handleProductClick = (productId) => {
    navigate(`/dashboard/product-upload/${productId}`); 
  };

  const handleNewProductClick = () => {
    navigate(`/dashboard/product-upload`);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        await uploadProductCSV(phone, selectedFile);
        alert("File uploaded successfully.");
        fileInputRef.current.value = ''; 
      } catch (error) {
        alert("There was an error uploading the file.");
      }
    }
  };

  const handleUploadClick = (download = false) => {
    if (download) {
      const link = document.createElement('a');
      link.href = '/products.csv'; 
      link.download = 'plantilla.csv'; 
      link.click();
    } else {
      fileInputRef.current.click(); 
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className={styles.formContainer}>
      <div className={styles.addNewContainer}>
        <h2 className={styles.title}>Productos</h2>
        <div className={styles.buttonSideBySide}>
          <button className={styles.addButton} onClick={handleNewProductClick}>Agregar producto</button>
          <div className={styles.dropdownContainer} ref={dropdownRef}>
            <button 
              className={styles.dropdownButton} 
              onClick={toggleDropdown}
            >
              Importar productos
            </button>
            <button 
              className={styles.dropdownButtonMobile} 
              onClick={toggleDropdown}
            >
              <img src={dotsIcon} alt='dots icon'/>
            </button>
            {dropdownOpen && (
              <div className={styles.dropdownMenu}>
                <button className={styles.dropdownItem} onClick={() => handleUploadClick(false)}>
                  Agregar archivo <img src={ImportIcon} alt='import' className={styles.dropdownIcon} />
                </button>
                <button className={styles.dropdownItem} onClick={() => handleUploadClick(true)}>
                  Descargar plantilla CSV
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
      <div className={styles.uploadContainer}>
        <input 
          type="file" 
          accept=".csv" 
          onChange={handleFileChange} 
          className={styles.fileInput} 
          ref={fileInputRef} 
          style={{ display: 'none' }} 
        />
      </div>
      <ProductList 
        products={filteredProducts} 
        showDeleteButton={false} 
        onProductClick={handleProductClick}
      />
    </div>
  );
};

export default UserProducts;
