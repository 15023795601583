import React from 'react';
import PropTypes from 'prop-types';
import styles from "../../assets/styles/FilesUpload.module.css";

const ImageUploadField = ({
  id,
  info,
  validated,
  errors,
  handleFileChange,
  icon,
  label,
  em = false,
  labelClassName,
  required = false,
  containerClassName,
  labelPosition = 'before',
  iconStyleProp = 'iconStyle',
  showFileName = true
}) => {
  const fileSrc = info && info[id] instanceof File ? URL.createObjectURL(info[id]) : null;

  const [mainLabel, subLabel] = em ? label.split('(') : [label];

  return (
    <div className={styles.inputContainer}>
      {labelPosition === 'before' && (
        <p className={styles.docText}>
          {mainLabel}
          {em && subLabel && (
            <em className={styles.emField}>{`(${subLabel}`}</em>
          )}
        </p>
      )}
      <div className={styles[containerClassName]}>
        <label htmlFor={id} className={styles[labelClassName]}>
          <input
            type="file"
            id={id}
            name={id}
            onChange={handleFileChange}
            className={styles.hiddenInput}
            required={required}
          />
          {!info || !info[id] ? (
            <img
              src={icon}
              alt={`${label} Icon`}
              className={styles[iconStyleProp]}
            />
          ) : (
            <img
              src={fileSrc}
              alt={`${label} Preview`}
              className={styles.imagePreview}
            />
          )}
        </label>
        {labelPosition === 'after' && (
          <p className={styles.docTextMedium}>
            {mainLabel}
            {em && subLabel && (
              <em className={styles.emField}>{`(${subLabel}`}</em>
            )}
          </p>
        )}
        <div className={styles.docTextContainer}>
          {info[id] && showFileName && (
            <div className={styles.fileName}>{info[id].name}</div>
          )}
        </div>
        {!validated[id] && errors[id] && (
          <div className={styles.error}>{errors[id]}</div>
        )}
      </div>
    </div>
  );
};

ImageUploadField.propTypes = {
  id: PropTypes.string.isRequired,
  info: PropTypes.object,
  validated: PropTypes.object,
  errors: PropTypes.object,
  handleFileChange: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  em: PropTypes.bool,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  containerClassName: PropTypes.string,
  labelPosition: PropTypes.oneOf(['before', 'after']),
  iconStyleProp: PropTypes.oneOf(['iconStyle', 'iconStyle2']),
  showFileName: PropTypes.bool
};

export default ImageUploadField;