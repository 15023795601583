import React, { useContext } from "react";
import { CartContext } from "../../contexts/CartContext";
import styles from "./Cart.module.css";
import { useCartOperations } from "../../hooks/cart_hooks/useCartOperations";
import { ReactComponent as ReturnIcon } from "../../../assets/icons/return-arrow.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/remove-icon.svg";

const Cart = ({ phone, onClose, catalogParam }) => {
  const { state } = useContext(CartContext);
  const { increaseQuantity, decreaseQuantity, removeItem, handleCheckout, isItemWithPromotion } = useCartOperations(phone, catalogParam);

  console.log(state.items, "Items with promo")
  return (
    <div className={styles.cartContainer}>
      <header className={styles.cartHeader}>
        <button onClick={onClose} className={styles.returnButton}>
          <ReturnIcon className={styles.returnIcon} />
        </button>
        <h2 className={styles.cartTitle}>Tu carrito</h2>
      </header>
      <section>
        {state.items.map((item, index) => {
          const isPromotion = isItemWithPromotion(item.productId);
          console.log(isPromotion, "Has promo!")
          return (
            <div key={index} className={styles.cartItem}>
              <img
                src={item.productImages[0]}
                alt={item.productName}
                className={styles.cartItemImage}
              />
              <div className={styles.cartItemDetails}>
                <div className={styles.cartItemName}>{item.productName}</div>
                <div className={styles.cartItemPrice}>
                  {isPromotion ? (
                    <>
                      <span className={styles.crossedOutPrice}>
                        ${item.productPrice} MXN
                      </span>
                      <span className={styles.promotionPrice}>
                        ${item.productOfferPrice} MXN
                      </span>
                    </>
                  ) : (
                    <span>${item.productPrice} MXN</span>
                  )}
                </div>
              </div>
              <div className={styles.cartItemQuantityRemoveWrapper}>
                <button
                  className={styles.cartItemRemove}
                  onClick={() => removeItem(item.id)}
                >
                  <RemoveIcon />
                </button>
                <div className={styles.cartItemQuantity}>
                  <button
                    className={styles.quantityDecrease}
                    onClick={() => decreaseQuantity(item.id)}
                  >
                    -
                  </button>
                  <div className={styles.quantityCount}>{item.quantity}</div>
                  <button
                    className={styles.quantityIncrease}
                    onClick={() => increaseQuantity(item.id)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      <section className={styles.cartSummary}>
        <div className={styles.cartTotal}>
          <span>Total</span>
          <span>${state.total} MXN</span>
        </div>
        <button onClick={handleCheckout} className={styles.checkoutButton}>
          Proceder a pagar
        </button>
      </section>
    </div>
  );
};

export default Cart;