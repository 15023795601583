import React from 'react'
import InputField from '../../components/utils/InputField';
import ChangeAlert from '../../components/ui/ChangeAlert';
import DropdownInputField from '../../components/utils/DropdownMenu';
import StatusToggle from '../../components/utils/StatusToggle';
import ContinueButton from '../../components/utils/ContinueButton';
import styles from "../../styles/ProductUploadForm.module.css";
import desktopStyles from '../../styles/DesktopStyles.module.css';
import ImageUploader from '../../components/utils/ImageUploader';

const DesktopProductUploadForm = ({
    product, 
    isButtonDisabled, 
    handleReset, 
    handleInputChange, 
    handleImageChange, 
    categories, 
    previewImages, 
    PriceIcon, 
    OfferPriceIcon, 
    loading, 
    success, 
    handleDeleteClick
}) => {
  return (
    <>
        <section className={styles.DesktopSection}>
            <ChangeAlert text="el producto" isVisible={!isButtonDisabled} onDiscard={handleReset}/>
            <div className={styles.titleActive}>
            <h1 className={styles.title}>
                {product.productName || 'Añadir producto'}
            </h1>
            <span className={product.status === 'true' ? styles.active : styles.inactive}>
                {product.status === 'true' ? 'Activo' : 'Inactivo'}
            </span>
            </div>
            <div className={desktopStyles.section2}>
            <InputField
                type="text"
                name="productName"
                placeholder="Nombre del producto"
                label="Nombre"
                value={product.productName}
                onChange={handleInputChange}
                required={true}
            />
            <InputField
                type="textarea"
                name="productDescription"
                placeholder="Descripción del producto"
                label="Descripción"
                value={product.productDescription}
                onChange={handleInputChange}
            />
            <article className={styles.imageUploader}>
                <ImageUploader
                previewImages={previewImages}
                handleImageChange={handleImageChange}
                />
            </article>
            <DropdownInputField
                categories={categories}
                name="productCategory"
                placeholder="[Categoría]"
                label="Categoría"
                value={product.productCategory}
                onChange={handleInputChange}
            />
            </div>
            <div className={desktopStyles.section2}>
            <h2 className={desktopStyles.title}>Precios</h2>
            <div className={styles.inputSideBySide}>
                <InputField
                type="text"
                name="productPrice"
                placeholder="MXN"
                label="Precio base"
                value={product.productPrice}
                onChange={handleInputChange}
                icon={PriceIcon}
                />
                <InputField
                type="text"
                name="productOfferPrice"
                placeholder="MXN"
                label="Precio oferta"
                value={product.productOfferPrice}
                onChange={handleInputChange}
                icon={OfferPriceIcon}
                />
            </div>
            </div>
            <div className={desktopStyles.section2}>
            <h2 className={desktopStyles.title}>Inventario</h2>
            <div className={styles.inputSideBySide}>
                <InputField
                type="text"
                name="productId"
                placeholder="[SKU producto]"
                label="SKU (ID producto)"
                value={product.productId}
                onChange={handleInputChange}
                />
                <InputField
                type="select"
                name="productInventory"
                placeholder="0"
                label="Existencias"
                value={product.productInventory}
                onChange={handleInputChange}
                />
            </div>
            </div>
            <div className={desktopStyles.section2}>
            <h2 className={desktopStyles.title}>Variantes</h2>
            <DropdownInputField
                type="text"
                name="size"
                placeholder="Lista tamaños (XS, S, M, L, XL)"
                options={['XS', 'S', 'M', 'L', 'XL']}
                label="Talla / tamaño"
                value={product.size}
                onChange={handleInputChange}
            />
            </div>
        </section>
        <section className={styles.desktopSection2}>
            <a
            href={product.productName ? `/shop/product/${product.productId}` : '#'}
            target={product.productName ? "_blank" : ""}
            rel="noopener noreferrer"
            className={`${styles.viewButton} ${!product.productName ? styles.disabled : ''}`}
            onClick={(e) => {
                if (!product.productName) e.preventDefault(); 
            }}
            >
            Ver
            </a>
            <div className={desktopStyles.statusSection}>
            <h2 className={desktopStyles.title}>Status</h2>
            <StatusToggle
                checked={product.status}
                onChange={handleInputChange}
            />
            {product.productName && (
                <button className={styles.deleteButton} type='button' onClick={handleDeleteClick}>
                Eliminar producto
                </button>
            )}
            </div>
            <ContinueButton type="submit" isLoading={loading} isSuccess={success} disabled={isButtonDisabled}/>
        </section>
    </>
  )
}

export default DesktopProductUploadForm
