import { useState, useEffect, useMemo } from 'react';
import { patchCategory } from '../services/userUpdateData';

const useCategoryForm = (category) => {
  const initialData = useMemo(() => ({
    newName: category?.categoryName || '',
    newDescription: category?.categoryDescription || "",
    categoryBanner: category?.categoryBanner || "",
    oldBanner: category?.categoryBanner || '',
    oldName: category?.categoryName || '',
    oldDescription: category?.categoryDescription || '',
  }), [category]);

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const tokenData = localStorage.getItem('accessToken');
  const { token } = JSON.parse(tokenData);

  const logFormData = (updatedFormData) => {
    console.log('Current formData:', updatedFormData);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && name === 'categoryBanner') {
      handleFileChange(name, files[0]);
    } else {
      handleGeneralInputChange(name, value);
    }
  };

  const handleGeneralInputChange = (fieldName, value) => {
    const updatedFormData = { ...formData, [fieldName]: value };
    setFormData(updatedFormData);
    logFormData(updatedFormData);
  };

  const handleFileChange = (field, file) => {
    const updatedFormData = {
      ...formData,
      [field]: file || '',
    };
    setFormData(updatedFormData);
    logFormData(updatedFormData);
  };

  useEffect(() => {
    const isModified = Object.keys(initialData).some(
      key => formData[key] !== initialData[key]
    );
    setIsButtonDisabled(!isModified);
  }, [formData, initialData]);

  const createFormData = () => {
    const bodyFormData = new FormData();

    const oldFieldMapping = {
      newName: 'oldName',
      newDescription: 'oldDescription',
      categoryBanner: 'oldBanner',
    };

    Object.entries(formData).forEach(([key, value]) => {
      if (value instanceof File) {
        console.log(`Appending file to FormData: ${key}`, value);
        bodyFormData.append(key, value);
      } else {
        console.log(`Appending data ${key}`, value);
        bodyFormData.append(key, value);
      }
    });

    Object.entries(oldFieldMapping).forEach(([newKey, oldKey]) => {
      const oldValue = formData[oldKey] || initialData[oldKey];
      if (oldValue && !bodyFormData.has(oldKey)) {
        console.log(`Appending old data ${oldKey}`, oldValue);
        bodyFormData.append(oldKey, oldValue);
      }
    });

    return bodyFormData;
  };

  const updateCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const bodyFormData = createFormData();
    try {
      const response = await patchCategory(token, bodyFormData);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 1500);
      return response;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
      setIsButtonDisabled(true);
    }
  };

  const handleReset = () => {
    setFormData(initialData)
  }

  return {
    formData,
    setFormData,
    loading,
    success,
    error,
    handleChange,
    updateCategory,
    isButtonDisabled,
    handleReset,
    setIsButtonDisabled
  };
};

export default useCategoryForm;
