import React, { useState } from "react";
import styles from "./ProductDropdown.module.css";

const ProductDropdown = ({ products, selectedProducts, onSelectProduct, onRemoveProduct, copy }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleProductClick = (productId) => {
    if (selectedProducts.some(product => product.id === productId)) {
      onRemoveProduct(productId);
    } else {
      onSelectProduct(productId);
    }
  };

  return (
    <div className={styles.dropdownContainer}>
      <button
        className={styles.addButton}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {copy}
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {products.map((product) => (
            <li
              key={product.id}
              className={`${styles.dropdownItem} ${
                selectedProducts.some(selected => selected.id === product.id) ? styles.selected : ""
              }`}
              onClick={() => handleProductClick(product.id)} 
              >
              <div className={styles.productInfo}>
                {product.productImages && product.productImages.length > 0 ? (
                  <img src={product.productImages[0]} alt={product.productName} />
                ) : (
                  <div className={styles.noImage}></div>
                )}
                <span>{product.productName}</span>
              </div>
              <input
                type="checkbox"
                checked={selectedProducts.some(selected => selected.id === product.id)}
                readOnly
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductDropdown;
