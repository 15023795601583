import React from 'react';
import { Navigate } from 'react-router-dom';
import { useParamsContext } from '../../context/ParamsProvider';

const isAuthenticated = () => {
  const tokenData = localStorage.getItem('accessToken');
  if (!tokenData) return false;

  try {
    const { token, expiresAt } = JSON.parse(tokenData);

    const isExpired = new Date().getTime() > expiresAt;
    if (isExpired) {
      localStorage.removeItem('accessToken');
      return false;
    }

    return !!token;
  } catch (error) {
    console.error('Failed to parse token data:', error);
    localStorage.removeItem('accessToken');
    return false;
  }
};

const PrivateRoute = ({ children }) => {
  const { phone } = useParamsContext();
  return isAuthenticated() ? children : <Navigate to={`/signin?phone=${phone}`} />;
};

export default PrivateRoute;