import React from "react";
import styles from "../../SignUpForm.module.css";
import PropTypes from "prop-types";

const InputField = ({
  em = false,
  label,
  type,
  name,
  placeholder,
  personalInfo,
  errors = {},
  validatedFields = {},
  handleChange,
  handleBlur,
  disabled = false,
  renderAdditional,
  required = true,
}) => {
  const isValidated = validatedFields[name];

  const [mainLabel, subLabel] = em ? label.split("(") : [label];

  return (
    <label htmlFor={`input-${name}`} className={`${styles.formField}`}>
      <div className={styles.labelContainer}>
        {mainLabel}
        {renderAdditional && renderAdditional()}
      </div>
      {em && subLabel && (
        <em className={styles.emInputField}>{`(${subLabel}`}</em>
      )}
      <div className={styles.inputContainer}>
        <input
          id={`input-${name}`}
          type={type}
          name={name}
          placeholder={placeholder}
          value={personalInfo[name] || ""}
          onChange={handleChange}
          onBlur={handleBlur ? (e) => handleBlur(e) : undefined}
          className={styles.inputField}
          disabled={disabled}
          required={required}
          maxLength={name === "clabe" ? 22 : undefined}
        />
        <div
          className={`${styles.validationIndicator} ${
            isValidated ? "validated" : ""
          }`}
        ></div>
      </div>
      {errors[name] && <div className="error">{errors[name]}</div>}
    </label>
  );
};

InputField.propTypes = {
  em: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  personalInfo: PropTypes.object.isRequired,
  errors: PropTypes.object,
  validatedFields: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  renderAdditional: PropTypes.func,
  required: PropTypes.bool,
};

export default InputField;
