import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/UserCategory.module.css";
import desktopStyles from "../../styles/DesktopStyles.module.css";
import ProductList from "../../components/navigation/ProductList";
import InputField from "../../components/utils/InputField";
import ContinueButton from "../../components/utils/ContinueButton";
import useCategoryForm from "../../hooks/useCategoryForm";
import useGeneralUpdate from "../../hooks/useGeneralUpdate";
import ProductDropdown from "../../components/utils/ProductDropdown";
import ChangeAlert from "../../components/ui/ChangeAlert";
import PopUpMessage from "../../components/ui/PopUpMessage";
import usePopUpMessage from "../../hooks/usePopUpMessage";


const UserCategory = ({ phone }) => {
  const location = useLocation();
  const { category } = location.state || {};
  const categoryName = category ? category.categoryName : "";
  const { popUp, handleSuccess, handleFailure, setPopUp } = usePopUpMessage();
  const navigate = useNavigate();  

  const {
    formData,
    handleChange,
    updateCategory,
    loading,
    isButtonDisabled,
    setIsButtonDisabled,
    handleReset, 
    setFormData,
    success
  } = useCategoryForm(category);

  const { 
    allProducts, 
    deleteProductCategory, 
    products, 
    fetchProductsFromCategory,
    selectedProducts,
    addProductToSelection,
    removeProductFromSelection, 
    submitUpdates 
  } = useGeneralUpdate(categoryName, phone, formData);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    if (formData.oldName !== "") {
      fetchProductsFromCategory();
    }
  }, [fetchProductsFromCategory, formData.oldName]);

  useEffect(() => {
    const mergedProducts = [...products];
    selectedProducts.forEach((selectedProduct) => {
      if (!mergedProducts.some(product => product.id === selectedProduct.id)) {
        mergedProducts.push(selectedProduct);
      }
    });
    setDisplayedProducts(mergedProducts);
  }, [products, selectedProducts]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedProducts, displayedProducts, setIsButtonDisabled]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate(-1);  
      }, 1000); 

      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  const bannerPreview =
    formData.categoryBanner && formData.categoryBanner instanceof File
      ? URL.createObjectURL(formData.categoryBanner)
      : formData.oldBanner;

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      updateCategory(e);
      setFormData(prevFormData => ({
        ...prevFormData,
        oldName: prevFormData.newName,
        oldDescription: prevFormData.newDescription,
        oldBanner: prevFormData.categoryBanner,
      }));
      submitUpdates();
      handleSuccess('¡Tu categoría se guardo correctamente!');
    } catch {
      handleFailure('¡Hubo un problema al intentar actualizar tu categoria!.Intenta más tarde.');
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <section className={styles.mobileSection}>
        <div className={styles.banner}>
          {bannerPreview ? (
            <img src={bannerPreview} alt={formData.newName || formData.oldName} />
          ) : (
            <span>Foto de portada</span>
          )}
          <input
            type="file"
            accept="image/*"
            id="categoryBanner"
            name="categoryBanner"
            className={styles.fileInput}
            onChange={handleChange}
          />
        </div>
        <h2 className={styles.categoryName}>
          {formData.newName || formData.oldName}
        </h2>
        <div className={styles.fieldContainer}>
          <InputField
            label="Nombre y descripción"
            value={formData.newName}
            name="newName"
            type="text"
            placeholder="Nombre"
            onChange={handleChange}
          />
        </div>
        <div className={styles.fieldContainer}>
          <InputField
            type="textarea"
            name="newDescription"
            value={formData.newDescription}
            placeholder="Descripción"
            maxLength="55"
            onChange={handleChange}
          />
        </div>
        <div className={styles.uploadButtonContainer}>
          <h3 className={styles.Title}>Lista de productos</h3>
          <ProductDropdown
            products={allProducts}
            selectedProducts={selectedProducts}
            onSelectProduct={addProductToSelection}
            onRemoveProduct={removeProductFromSelection}
            copy="+"
            isOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
          />
        </div>
        <ProductList products={displayedProducts} onDelete={deleteProductCategory} showDeleteButton={true}/>
        <p className={styles.editNote}>
          Para editar productos ve a la pestaña de mis productos / servicios
        </p>
      </section>

      {/* DESKTOP */}
      <div className={styles.desktopSection}>
        <ChangeAlert text="la categoría" isVisible={!isButtonDisabled} onDiscard={handleReset}/>
        {formData.newName && (
          <div className={styles.categoryTitle}>
            <h1 className={styles.title}>{formData.newName}</h1>
            <a
              href={`/shop/catalog/aida_link/category/${categoryName}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.viewButton}
            >
              Ver
            </a>
          </div>
        )}
        <section className={desktopStyles.section}>
          <h3 className={desktopStyles.subtitle}>Portada</h3>
          <label className={styles.imageContainer}>
            {bannerPreview ? (
              <img src={bannerPreview} alt="Selected" className={styles.imagePreview} />
            ) : (
              <div className={styles.placeholder}>
                <button className={styles.addButton}>Añadir imagen</button>
                <p>Tamaño recomendado 850 x 315 píxeles</p>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className={styles.imageInput}
              onChange={handleChange}
              id="categoryBanner"
              name="categoryBanner"
            />
          </label>
        </section>
        <section className={desktopStyles.section}>
          <InputField
            label="Nombre"
            value={formData.newName}
            name="newName"
            type="text"
            showUpdateButton={true}
            placeholder="Nombre"
            onChange={handleChange}
          />
          <InputField
            label="Descripción"
            type="textarea"
            name="newDescription"
            value={formData.newDescription}
            placeholder="Descripción"
            showUpdateButton={true}
            maxLength="55"
            onChange={handleChange}
          />
        </section>
        <section className={desktopStyles.section}>
          <div className={styles.uploadButtonContainer}>
            <h3 className={styles.Title}>Lista de productos</h3>
            <ProductDropdown
              copy="+ Añadir producto"
              products={allProducts}
              selectedProducts={selectedProducts} 
              onSelectProduct={addProductToSelection} 
              onRemoveProduct={removeProductFromSelection} 
              isOpen={isDropdownOpen}
              toggleDropdown={toggleDropdown}
            />
          </div>
          <hr className={styles.headruler}/>
          <ProductList products={displayedProducts} onDelete={deleteProductCategory} showDeleteButton={true}/>
        </section>
      </div>
      <ContinueButton type="submit" isLoading={loading} disabled={isButtonDisabled} />
      <PopUpMessage
        title={popUp.title}
        message={popUp.message}
        isVisible={popUp.isVisible}
        onClose={() => setPopUp({ ...popUp, isVisible: false })}
      />
    </form>
  );
};

export default UserCategory;