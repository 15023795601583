import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import styles from "../../styles/ProductUploadForm.module.css";
import InputField from '../../components/utils/InputField';
import ImageUploader from '../../components/utils/ImageUploader.js';
import useProductForm from '../../hooks/useProductForm'; 
import StatusToggle from '../../components/utils/StatusToggle.js';
import ContinueButton from '../../components/utils/ContinueButton.js';
import DropdownInputField from '../../components/utils/DropdownMenu.js';
import { getCategoryData } from '../../services/userData.js';
import { ReactComponent as PriceIcon } from '../../../assets/icons/price-icon.svg';
import { ReactComponent as OfferPriceIcon } from '../../../assets/icons/offerPrice-icon.svg';
import PopUpMessage from '../../components/ui/PopUpMessage.js';
import usePopUpMessage from '../../hooks/usePopUpMessage.js';
import DesktopProductUploadForm from './DesktopProductUploadForm.js';

const ProductUploadForm = ({ phone }) => {
  const { popUp, handleSuccess, handleFailure, setPopUp } = usePopUpMessage();

  const {
    product,
    previewImages,
    handleInputChange,
    handleImageChange,
    handleSubmit,
    handleDeleteClick,
    loading,         
    success,        
    isButtonDisabled, 
    handleReset,
    errors
  } = useProductForm(phone);

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryData = await getCategoryData(phone);
        const filteredCategories = categoryData.filter(category => category.categoryName !== 'Feed');
        setCategories(filteredCategories || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [phone]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate(-1);  
      }, 1000); 

      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  const handleSubmitAndCreate = async (e) => {
    e.preventDefault();
    try {
        const result = await handleSubmit(e);
        console.log(result, "resultado handleSubmit")
        if (result) {
            handleSuccess('¡Tu producto se guardó correctamente!.');
        } else {
            throw new Error('Error while saving product');
        }
    } catch (error) {
      const errorMessage = errors ? errors || '¡Hubo un problema al intentar actualizar tu producto! Intenta más tarde.' : '¡Hubo un problema al intentar actualizar tu producto! Intenta más tarde.';
      handleFailure(errorMessage);    }
};

  return (
    <form className={styles.formContainer} onSubmit={handleSubmitAndCreate}>
      <section className={styles.mobileSection}>
        <h1 className={styles.title}>
          {product.productName || 'Nuevo producto'}{' '}
          <span className={product.status === 'true' ? styles.active : styles.inactive}>
            {product.status === 'true' ? 'Activo' : 'Inactivo'}
          </span>
        </h1>
        <div className={styles.form}>
          <InputField
            type="text"
            name="productName"
            placeholder="Nombre del producto"
            label="Nombre"
            value={product.productName}
            onChange={handleInputChange}
            required={true}
          />
          <InputField
            type="textarea"
            name="productDescription"
            placeholder="Descripción del producto"
            label="Descripción"
            value={product.productDescription}
            onChange={handleInputChange}
          />
          <div className={styles.inputSideBySide}>
            <InputField
              type="text"
              name="productPrice"
              placeholder="MXN"
              label="Precio"
              value={product.productPrice}
              onChange={handleInputChange}
              icon={PriceIcon}
            />
            <InputField
              type="text"
              name="productOfferPrice"
              placeholder=" MXN"
              label="Precio de oferta"
              value={product.productOfferPrice}
              onChange={handleInputChange}
              icon={OfferPriceIcon}
            />
          </div>
          <div className={styles.inputSideBySide}>
            <DropdownInputField
              categories={categories}
              name="productCategory"
              placeholder="[Categoría]"
              label="Categoría"
              value={product.productCategory}
              onChange={handleInputChange}
            />
            <InputField
              type="select"
              name="productInventory"
              placeholder="[Existencias]"
              label="Existencias"
              value={product.productInventory}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.inputSideBySide}>
            <ImageUploader
              previewImages={previewImages}
              handleImageChange={handleImageChange}
            />
            <div className={styles.inputColumn}>
              <InputField
                type="text"
                name="productId"
                placeholder="[SKU producto]"
                label="SKU (ID producto)"
                value={product.productId}
                onChange={handleInputChange}
              />
              <DropdownInputField
                type="text"
                name="size"
                placeholder="Lista tamaños (XS, S, M, L, XL)"
                options={['XS', 'S', 'M', 'L', 'XL']}
                label="Talla / tamaño"
                value={product.size}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <h3 className={styles.subtitle}>Imagen principal</h3>
          <p className={styles.sub}>Para tener un catálogo mas profesional, intenta que tu imagen principal tenga un fondo blanco</p>
          <h2 className={styles.status}>Status</h2>
          <StatusToggle
            checked={product.status}
            onChange={handleInputChange}
          />
          <button className={styles.deleteButton} type='button' onClick={handleDeleteClick}>
            Eliminar producto
          </button>
          <ContinueButton type="submit" isLoading={loading} isSuccess={success} disabled={isButtonDisabled}/>
        </div>
      </section>
     
      <DesktopProductUploadForm
          product={product}
          isButtonDisabled={isButtonDisabled}
          handleReset={handleReset}
          handleInputChange={handleInputChange}
          handleImageChange={handleImageChange}
          categories={categories}
          previewImages={previewImages}
          PriceIcon={PriceIcon}
          OfferPriceIcon={OfferPriceIcon}
          loading={loading}
          success={success}
          handleDeleteClick={handleDeleteClick}
      />
      <PopUpMessage
        title={popUp.title}
        message={popUp.message}
        isVisible={popUp.isVisible}
        onClose={() => setPopUp({ ...popUp, isVisible: false })}
      />
    </form>
  );
};

export default ProductUploadForm;
