import { useState, useEffect } from 'react';

export const useStepManager = (initialStep) => {
  const [step, setStep] = useState(() => {
    const savedStep = localStorage.getItem('currentStep');
    return savedStep ? savedStep : initialStep;
  });

  const [history, setHistory] = useState(() => {
    const savedHistory = localStorage.getItem('stepHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });

  useEffect(() => {
    localStorage.setItem('currentStep', step);
  }, [step]);

  useEffect(() => {
    localStorage.setItem('stepHistory', JSON.stringify(history));
  }, [history]);

  const nextStep = (next) => {
    setHistory((prevHistory) => [...prevHistory, step]);
    setStep(next);
    console.log(history)
  };

  const prevStep = () => {
    if (history.length > 0) {
      const newHistory = [...history];
      const previousStep = newHistory.pop();
      setStep(previousStep);
      setHistory(newHistory);
    }
  };

  return { step, setStep, nextStep, prevStep };
};