import React, { useEffect, useRef, useState } from 'react';
import useProductSearch from '../../hooks/search_hooks/useSearchProduct';
import styles from './Search.module.css';
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from "../../../assets/icons/searchIcon.svg";

const ProductSearchComponent = ({ categories, categoryProducts }) => {
  const {
    searchTerm,
    setSearchTerm,
    filteredCategoryProducts,
  } = useProductSearch( categories, categoryProducts);

  const searchRef = useRef(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value !== '') {
      setIsSearchVisible(true);
    }
  };

  const handleSuggestionClick = () => {
    setSearchTerm('');
    setIsSearchVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.searchContainer}>
      <button className={styles.searchButton}><SearchIcon className={styles.searchIcon}/></button>
      <input
        type="text"
        className={styles.searchInput}
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setIsSearchVisible(true)}
        onBlur={() => {
          if (searchTerm === '') {
            setIsSearchVisible(false);
          }
        }}
      />
      {isSearchVisible && searchTerm && (
        <div ref={searchRef} className={styles.suggestionsContainer}>
          <ul className={styles.suggestionsList}>
            {Object.entries(filteredCategoryProducts).map(([category, products]) => products.map(product => (
              <Link to={`/shop/product/${product.productId || product.id}`} key={product.productId}>
                <li onClick={handleSuggestionClick}>
                  <img src={product.productImages[0]} alt="product"/>
                  {product.productName}
                </li>
              </Link>
            )))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductSearchComponent;