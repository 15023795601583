import React from 'react'
import styles from '../../styles/UserServices.module.css'
import RightArrow from '../../../assets/icons/rightArrow.svg';
import { Link } from 'react-router-dom';
import CatalogLink from '../../components/ui/CatalogLink';
import { handleCopy } from '../../utils/shareUtils.js';
import { handleShare } from '../../utils/shareUtils.js';

const UserServices = ({ userNameData, phone }) => {
  return (
    <div className={styles.formContainer}>
      <h1 className={styles.title}>
        Tiendas Digitales
      </h1>
      <p className={styles.subtitle}>Cátalogo de mi negocio</p>
      <article className={styles.userData}>
        <div className={styles.avatar}>
          <img src={userNameData.businessLogo} alt="User Avatar" className={styles.avatar} />
        </div>
        <div className={styles.info}>
          <p className={styles.userName}>{userNameData ? userNameData.businessName : ''}</p>
          <p className={styles.businessName}>{userNameData ? userNameData.businessDescription : ""}</p>
          <Link to="/dashboard/shop" className={styles.button}>
            <p className={styles.text}>Crear o editar tienda digital</p>
            <div className={styles.arrow}>
              <img src={RightArrow} alt="arrow" />
            </div>
        </Link>
        </div>
      </article>
      <p className={styles.subDescription}>Personaliza tú tienda digital, añade tus productos / servicios, colores y portada</p>
      <section className={styles.mobileSection}>
        <h2 className={styles.activeCatalogsText}>
          Catálogos activos
        </h2>
        <CatalogLink
          catalogName={userNameData.businessName}
          phone={phone}
          catalog="aida_link"
          handleShare={handleShare}
          handleCopy={handleCopy}
        />
        {userNameData.catalogs.map((catalog) => {
          if (catalog.isSuscription) {
            return (
              <CatalogLink
              key={catalog.catalogName}
              catalogName={catalog.catalogName}
              phone={phone}
              catalog={catalog.catalogName}
              handleShare={handleShare}
              handleCopy={handleCopy}
            />
            );
          }
          return null;
        })}
      </section>
    </div>
  )
}

export default UserServices
