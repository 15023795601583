import { useContext } from 'react';
import { CartContext } from "../../contexts/CartContext";
import { updateCartFromServer } from '../../services/cartServices';

export const useCartOperations = (phone, catalogParam) => {
    const { state, dispatch } = useContext(CartContext);

    const increaseQuantity = (id) => {
        const product = state.items.find((item) => item.id === id);
        dispatch({ type: "ADD_ITEM", payload: product });
    };

    const decreaseQuantity = (id) => {
        dispatch({ type: "REMOVE_ITEM", payload: { id } });
    };

    const removeItem = (id) => {
        dispatch({ type: "REMOVE_ALL_FROM_ITEM", payload: { id } });
    };

    const handleCheckout = async () => {
        try {
            const responseData = await updateCartFromServer(phone, state.items, catalogParam, true);
            if (responseData.paymentLink) {
                window.location.href = responseData.paymentLink;
            } else {
                console.error("No payment link received");
            }
        } catch (error) {
            console.error("Checkout failed:", error);
        }
    };

    const isItemWithPromotion = (productId) => {
        const product = state.products.find(product => product.productId === productId);
        console.log(product, "product is with promo?")
        if (product) {
          const isProductInOffer = product.isProductInOffer;
          console.log(isProductInOffer, "item con precio")
          return isProductInOffer;
        }
        return false;
      };

    return { increaseQuantity, decreaseQuantity, removeItem, handleCheckout, isItemWithPromotion };
};
