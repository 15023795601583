import axios from "axios";
const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API;

const constructUrl = (endpoint, id, query = "") => {
  return `${apiUrl}${endpoint}${id}${query ? `?${query}` : ""}`;
};

const constructUrlService = (endpoint, identifier, phone) => {
  let url = `${apiUrl}${endpoint}${identifier}`;
  if (phone) {
    url += `?phone=${phone}`;
  }
  return url;
};

const constructUrlCategory = (endpoint, identifier, query = "") => {
  let url = `${apiUrl}${endpoint}${identifier}`;
  if (query) {
    url += `?${query}`;
  }
  return url;
};

export const getUserProducts = async (phone, category) => {
  const endpoint = "/api/v1/products/user/";
  const query = category ? `category=${encodeURIComponent(category)}` : "";
  try {
    const response = await axios.get(constructUrl(endpoint, phone, query));
    return response.data;
  } catch (error) {
    console.error("Error fetching user's products:", error);
    throw new Error(
      `Error fetching user's products: ${
        error.response?.statusText || error.message
      }`
    );
  }
};

export const getCatalogProducts = async (catalogId, category = "", phone, page = 1, size = 500) => {
  const endpoint = "/api/v1/products/catalog/";
  let query = "";

  if (category) query += `category=${encodeURIComponent(category)}&`;
  if (phone) query += `phone=${phone}&`;
  if (page) query += `page=${page}&`;
  if (size) query += `size=${size}`;

  query = query.endsWith('&') ? query.slice(0, -1) : query;

  try {
    const response = await axios.get(constructUrlCategory(endpoint, catalogId, query));
    return response.data;
  } catch (error) {
    console.error("Error fetching catalog products:", error);
    throw new Error(
      `Error fetching catalog products: ${
        error.response?.statusText || error.message
      }`
    );
  }
};

export const fetchCategories = async (identifier, phone) => {
  const url = constructUrlService("/api/v1/products/category/", identifier, phone );
  const response = await axios.get(url);
  console.log(response);
  return response.data;
};

export const fetchProductsByCategory = async (categories, catalogId, phone) => {
  const fetchFunction = catalogId ? getCatalogProducts : getUserProducts;
  const identifier = catalogId || phone;
  const productsByCategoryPromises = categories.map(category =>
    fetchFunction(identifier, category, phone)
      .then(response => ({ name: category, products: response.products }))
      .catch(() => ({ name: category, products: [] }))
  );
  const productsByCategoryResults = await Promise.all(productsByCategoryPromises);
  return productsByCategoryResults.reduce((acc, curr) => {
    acc[curr.name] = curr.products;
    return acc;
  }, {});
};

export const getProductDetails = async (productIdOrId, phone, catalog) => {
  const endpoint = "/api/v1/products/data/";
  let query = "";

  if (phone) query += `phone=${phone}&`;
  if (catalog) query += `catalog=${catalog}`;

  query = query.endsWith('&') ? query.slice(0, -1) : query;

  try {
    const url = constructUrlCategory(endpoint, productIdOrId, query);
    const response = await axios.get(url);
    if (response.data && response.data.product) {
      console.log(url)

      return response.data.product;
    } else {
      console.log(url)

      console.log("No product data found in response:", response.data);
      return null; 
    }
  } catch (error) {
    console.error("Error fetching product details:", error);
    throw new Error(
      `Error fetching product details: ${
        error.response?.statusText || error.message
      }`
    );
  }
};