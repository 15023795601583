import React from 'react'
import styles from '../../assets/styles/BankDropdown.module.css'

const BankDropdown = ({ 
    legalInfo,
    handleInputChange,
    name
}) => {
  return (
    <div className={styles.dropDownContainer}>
        <label htmlFor="bankName" className={styles.dropLabel}>Banco</label>
        <select
        id="bankName"
        name={name}
        value={legalInfo[name]}
        onChange={handleInputChange}
        placeholder="Selecciona tu banco"
        required
        className={styles.selectField}
        >
        <option value="" disabled>
            Selecciona tu banco
        </option>
        {[
            "ABC Capital",
            "Afirme",
            "American Express",
            "Anáhuac",
            "Atlántico",
            "Autofin",
            "Azteca",
            "Bajío",
            "Banamex",
            "Banco del Centro (Bancen)",
            "Bancomer BBVA",
            "Banco del Bajío",
            "Banco Inbursa",
            "BanCoppel",
            "Bancrea",
            "Bank of America México (BAMSA)",
            "Bank of China México",
            "Bankaool",
            "BankBoston",
            "Barclays Bank México",
            "Bicentenario",
            "Capital",
            "Chihuahua",
            "Citibank México",
            "CIBanco",
            "Compartamos Banco",
            "ConsuBanco",
            "Credit Suisse (México)",
            "Deutsche Bank México",
            "Donde",
            "Famsa",
            "Finterra",
            "Forjadores",
            "GE Money Bank",
            "HSBC México",
            "Industrial",
            "Inmobiliario",
            "Interacciones",
            "Intercam Banco",
            "Investa Bank",
            "JP Morgan",
            "Mifel",
            "Monex",
            "Multiva",
            "Nationsbank de México",
            "Pagatodo",
            "Pronorte",
            "Quadrum",
            "Santander",
            "Scotiabank Inverlat",
            "Serfin",
            "Société Générale México",
            "Sureste",
            "Tokio",
            "Unión",
            "Ve Por Más",
            "Volkswagen Bank",
            "Wal-Mart",
        ].map((bank) => (
            <option key={bank} value={bank}>
            {bank}
            </option>
        ))}
        </select>
    </div>
  )
}

export default BankDropdown
