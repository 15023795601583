import React from 'react'
import styles from '../styles/Settings.module.css'
import SettingsLinks from '../components/navigation/SettingsLinks.js'
import { capitalizeFirstLetters } from '../utils/userUtils.js'
import CatalogLink from '../components/ui/CatalogLink.js';
import { handleCopy } from '../utils/shareUtils.js';
import { handleShare } from '../utils/shareUtils.js';

const Settings = ({ userNameData, phone }) => {

  const formattedName = userNameData && userNameData.firstNameLastName 
    ? capitalizeFirstLetters(userNameData.firstNameLastName) 
    : '';

  return (
    <div className={styles.formContainer}>
      <div className={styles.mobileSection}>
        <h1 className={styles.title}>
          Configura tu aida
        </h1>
        <article className={styles.userData}>
          <div className={styles.avatar}>
            <img src={userNameData.businessLogo} alt="User Avatar" className={styles.avatar} />
          </div>
          <div className={styles.info}>
            <p className={styles.userName}>{formattedName}</p>
            <p className={styles.businessName}>{userNameData ? userNameData.businessName : ''}</p>
          </div>
        </article>
        <article className={styles.settingsOptions}>
          <SettingsLinks iconName="business-icon" title="Negocio" route="/dashboard/my-bussines" />
          <SettingsLinks iconName="shop-icon" title="Tiendas digitales" route="/dashboard/services" />
          <SettingsLinks iconName="products-icon" title="Productos / servicios" route="/dashboard/products-services" />
        </article>
      </div>

      <section className={styles.desktopSection}>
        <h1 className={styles.userName}>Bienvenido, {formattedName}</h1>
        <h2 className={styles.title}>
          Catálogos activos
        </h2>
        <CatalogLink
          catalogName={userNameData.businessName}
          phone={phone}
          catalog="aida_link"
          handleShare={handleShare}
          handleCopy={handleCopy}
        />
        {userNameData.catalogs.map((catalog) => {
          if (catalog.isSuscription) {
            return (
              <CatalogLink
              key={catalog.catalogName}
              catalogName={catalog.catalogName}
              phone={phone}
              catalog={catalog.catalogName}
              handleShare={handleShare}
              handleCopy={handleCopy}
            />
            );
          }
          return null;
        })}
        <div className={styles.footer}>
          <hr/>
          <h3>Nuevas funciones pronto</h3>
          <hr/>
        </div>
      </section>
    </div>
  )
}

export default Settings