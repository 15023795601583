import { useState, useEffect } from 'react';
import { fetchProductsByCategory, fetchCategories } from '../../services/productService';
import { getUserData } from '../../services/userService';

export const useHomePageData = (phone, catalogParam) => {
  const [categories, setCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoadingUserData(true);
      try {
        const userData = await getUserData(phone, catalogParam);
        console.log(userData);
        setBusinessInfo(userData);
      } catch (error) {
        setError('Failed to fetch user data');
      } finally {
        setLoadingUserData(false);
      }
    };

    const fetchCategoriesData = async () => {
      setLoadingCategories(true);
      try {
        const fetchedCategories = await fetchCategories(catalogParam, phone);
        console.log(fetchedCategories, "categories");
        setCategories(fetchedCategories);
      } catch (error) {
        setError('Failed to fetch categories');
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchUserData();
    fetchCategoriesData();
  }, [phone, catalogParam]);

  useEffect(() => {
    if (categories.length > 0) {
      const fetchProductsData = async () => {
        setLoadingProducts(true);
        try {
          const productsByCategory = await fetchProductsByCategory(categories, catalogParam, phone);
          setCategoryProducts(productsByCategory);
        } catch (error) {
          setError('Failed to fetch products');
        } finally {
          setLoadingProducts(false);
        }
      };

      fetchProductsData();
    }
  }, [categories, catalogParam, phone]);

  useEffect(() => {
    setLoading(loadingCategories || loadingProducts || loadingUserData);
  }, [loadingCategories, loadingProducts, loadingUserData]);

  return { categories, categoryProducts, businessInfo, loading, error, loadingUserData };
}