import React from 'react';
import PropTypes from 'prop-types';
import styles from './CatalogLink.module.css'; 
import shareIcon from '../../../assets/icons/share-icon.svg';
import copyIcon from '../../../assets/icons/copy-icon.svg';

const CatalogLink = ({ catalogName, phone, catalog, handleShare, handleCopy }) => {
  const catalogNameLowercase = catalog.toLowerCase()
  const shopUrl = `/shop?phone=${phone}&catalog=${catalogNameLowercase}`;
  return (
    <div className={styles.desktopLinks}>
      <h3 className={styles.subtitle}>{catalogName}</h3>
      <a className={styles.link} target="_blank" rel="noreferrer" href={shopUrl}>
        shop.dev.soyaida.com{shopUrl}
      </a>
      <div className={styles.iconContainer}>
        <img src={shareIcon} alt="Share" onClick={() => handleShare(shopUrl)} />
        <img src={copyIcon} alt="Copy" onClick={() => handleCopy(shopUrl)} />
      </div>
    </div>
  );
};

CatalogLink.propTypes = {
  catalogName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  catalog: PropTypes.string.isRequired,
  handleShare: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired,
};

export default CatalogLink;
