import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/UserCategoryList.module.css';
import RightArrow from '../../../assets/icons/rightArrow.svg';
import { getCategoryData } from '../../services/userData.js';
import { SearchBar } from '../../components/utils/SearchBar.js';
import categoryIcon from '../../../assets/icons/categoryPlaceholder.svg';

const UserCategoryList = ({ phone }) => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryData = await getCategoryData(phone);
        const updatedCategories = categoryData
          .filter(category => category.categoryName !== 'Feed') // Filter out 'Feed' categories
          .map((category) => {
            if (category.categoryName === 'Feed') {
              return {
                ...category,
                categoryName: 'Sin categorizar',
              };
            }
            return category;
          });

        setCategories(updatedCategories || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [phone]);

  const filteredCategories = categories.filter((category) =>
    category.categoryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategoryClick = (category) => {
    navigate(`/dashboard/category/${category.categoryName}`, { state: { category } });
  };

  const handleCategory = () => {
    navigate(`/dashboard/category`);
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.addNewContainer}>
        <h2 className={styles.title}>Categorias</h2>
        <button className={styles.addButton} onClick={handleCategory}>Crear categoría</button>
      </div>
      <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
      <div className={styles.categoryList}>
        {filteredCategories.map((category, index) => (
          <div key={index} className={styles.categoryItem}
            onClick={() => handleCategoryClick(category)}
          >
            <div className={styles.categoryImageContainer}>
              {category.categoryBanner ? (
                <img src={category.categoryBanner} alt={category.categoryName} />
              ) : (
                <div className={styles.noImage}> <img src={categoryIcon} alt='category-icon'/> </div>
              )}
            </div>
            <div className={styles.categoryDetails}>
              <span className={styles.categoryName}>{category.categoryName}</span>
              <span className={styles.categoryDescription}>{category.categoryDescription}</span>
            </div>
            <button
              className={styles.linkButton}
              onClick={() => handleCategoryClick(category)}
            >
              <img src={RightArrow} alt="arrow" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCategoryList;
