// shop/services/userService.js

const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API; 

export const getUserData = async (phone, catalogParam) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/products/user/data/${phone}?catalog=${catalogParam}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch business info failed:", error);
    throw error;
  }
};
