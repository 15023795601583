import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputField.module.css';

const InputField = ({ 
  label, 
  type = 'text',
  value = '',
  onChange, 
  placeholder = "", 
  maxLength = 'none', 
  name,
  icon: Icon,
  required,
  noBorderOnDesktop = false, // Existing prop
  disabled = false // New prop with default value
}) => {
  const renderInputField = () => {
    const inputClassNames = `${styles.input} ${Icon ? styles.inputWithIcon : ''} ${noBorderOnDesktop ? styles.noBorderDesktop : ''}`;

    if (type === 'textarea') {
      return (
        <div className={styles.textareaContainer}>
          <textarea
            className={`${styles.textarea} ${noBorderOnDesktop ? styles.noBorderDesktop : ''}`}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            name={name}
            id={`input-${name}`}
            disabled={disabled} // Pass disabled prop
          />
          {maxLength !== 'none' && (
            <div className={styles.characterCounter}>
              {value.length}/{maxLength}
            </div>
          )}
        </div>
      );
    } else if (type === 'select') {
      const options = ["Unlimited", ...Array.from({ length: 1000 }, (_, i) => i + 1)];
      return (
        <select
          value={value}
          onChange={onChange}
          className={inputClassNames}
          name={name}
          id={`input-${name}`}
          disabled={disabled} // Pass disabled prop
        >
          <option value="">[Existencias]</option>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <div className={styles.inputContainer}>
          {Icon && <Icon className={styles.icon} />} 
          <input
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            className={inputClassNames}
            name={name}
            id={`input-${name}`}
            required={required}
            disabled={disabled} // Pass disabled prop
          />
        </div>
      );
    }
  };

  return (
    <label className={styles.inputField}>
      <span className={styles.labelText}>{label}</span>
      {renderInputField()}
    </label>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  icon: PropTypes.elementType, 
  required: PropTypes.bool,
  noBorderOnDesktop: PropTypes.bool,
  disabled: PropTypes.bool 
};

export default InputField;
