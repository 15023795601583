import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Carousel/ProductCarousel.css";
import Product from "./Product";

function ProductCarousel({ products = [], businessColor, catalogParam  }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  if (!Array.isArray(products) || products.length === 0) {
    return <div>No products available</div>;
  }

  return (
    <div className="categoryCarousel">
      <Slider {...settings}>
        {products.map((product, index) => (
          <Product product={product} index={index} key={index} businessColor={businessColor} catalogParam={catalogParam}/>
        ))}
      </Slider>
    </div>
  );
}

export default ProductCarousel;
