import React, { createContext, useCallback, useEffect, useReducer } from "react";
import { updateCartFromServer } from "../services/cartServices";

export const CartContext = createContext();

const initialState = {
  items: [],
  total: 0,
  alert: null,
};

const cartReducer = (state, action) => {
  const updatedItems = JSON.parse(JSON.stringify(state.items));
  switch (action.type) {
    case "ADD_ITEM": {
      const existingItemIndex = updatedItems.findIndex((item) => item.id === action.payload.id);

      if (existingItemIndex !== -1) {
        updatedItems[existingItemIndex].quantity++;
      } else {
        updatedItems.push({ ...action.payload, quantity: 1 });
      }

      return {
        ...state,
        items: updatedItems,
        alert: "Artículo añadido",
      };
    }
    case "REMOVE_ITEM": {
      const existingItemIndex = updatedItems.findIndex((item) => item.id === action.payload.id);
      if (existingItemIndex < 0) break;

      if (updatedItems[existingItemIndex].quantity <= 1) {
        updatedItems.splice(existingItemIndex, 1);
      } else {
        updatedItems[existingItemIndex].quantity--;
      }

      return {
        ...state,
        items: updatedItems,
      };
    }
    case "REMOVE_ALL_FROM_ITEM": {
      const existingItemIndex = updatedItems.findIndex((item) => item.id === action.payload.id);
      if (existingItemIndex < 0) break;
      updatedItems.splice(existingItemIndex, 1);

      return {
        ...state,
        items: updatedItems,
      };
    }
    case "CLEAR_ALERT": {
      return {
        ...state,
        alert: null,
      };
    }
    case "SET_TOTAL_AND_PRICES": {
      return {
        ...state,
        total: action.payload.total,
        products: action.payload.products
      };
    }
    case "SET_CART_FROM_REFRESH": {
      return {
        ...state,
        total: action.payload.total,
        products: action.payload.products,
        items: action.payload.items
      };
    }
    case "RESET_TOTAL": {
      return {
        ...state,
        total: 0,
      };
    }
    default:
      return state;
  }
};

export const CartProvider = ({ children, phone, catalogParam }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      try {
        const parsedCart = JSON.parse(storedCart);
        if (parsedCart && Array.isArray(parsedCart.items)) {
          updateCartFromServer(phone, parsedCart.items, catalogParam, false).then((result) => {
            if (result) {
              console.log(result, "resultado", parsedCart.items)
              dispatch({
                type: "SET_CART_FROM_REFRESH",
                payload: {
                  total: result.total,
                  products: result.products,
                  items: parsedCart.items  
                },
              });
            }
          });
        } else {
          console.warn("No valid parsed data")
        }
      } catch (error) {
        console.error("Error parsing stored cart data:", error);
      }
    }
  }, [phone, catalogParam]);

  useEffect(() => {
    const cartData = {
      items: state.items,
      phone,
    };
    localStorage.setItem("cart", JSON.stringify(cartData));
  }, [state.items, phone]);

  const fetchData = useCallback(async () => {
    if (state.items.length > 0) {
      const result = await updateCartFromServer(phone, state.items, catalogParam, false);
      console.log("Fetch result:", result);
      if (result) {
        dispatch({
          type: "SET_TOTAL_AND_PRICES",
          payload: result,
        });
      }
    } else {
      dispatch({
        type: "RESET_TOTAL",
      });
    }
  }, [phone, state.items, catalogParam]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;