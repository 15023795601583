const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API;

export const fetchRegistrationStep = async (phone) => {
  const response = await fetch(
    `${apiUrl}/api/v1/register/step/${phone}?businessType=`
  );
  if (!response.ok) {
    if (response.status === 409) {
      console.error(
        "Conflict detected. The phone number might already be used."
      );
    } else {
      throw new Error("Network response was not ok.");
    }
  }
  return response.json();
};

export const createCatalog = async (catalogInfo) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/register/user/catalog-subscription`,
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify(catalogInfo),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to create catalog:", error);
  }
};

export const submitFileData = async (fileData) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/register/user/files`, {
      method: "POST",
      body: fileData,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log("File upload response:", await response.json());
  } catch (error) {
    console.error("Failed to submit file data:", error);
  }
};

export const submitBusinessInfo = async (formData) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/register/business/info`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(
        `HTTP error! Status: ${response.status} - ${errorDetails}`
      );
    }
    const data = await response.json();
    console.log("Success:", data);
    return data;
  } catch (error) {
    console.error("Failed to submit business info:", error);
    throw error;
  }
};

export const submitProduct = async (formData) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/register/product`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log("Product submission successful:", await response.json());
    const data = await response.json();
    console.log("Success:", data);
    return data;
  } catch (error) {
    console.error("Failed to submit business info:", error);
    throw error;
  }
};

export const submitBusinessInfoCatalog = async (formData) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/register/betterware/business/info`,
      {
        method: "POST",
        body: formData,
      }
    );
    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(
        `HTTP error! Status: ${response.status} - ${errorDetails}`
      );
    }
    const data = await response.json();
    console.log("Success:", data);
    return data;
  } catch (error) {
    console.error("Failed to submit business info:", error);
    throw error;
  }
};

export const submitTextData = async (data) => {
  const endpoint = `${apiUrl}/api/v1/register/user/info`;
  try {
    console.log(JSON.stringify(data));
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Personal info response:", responseData);
    return true;
  } catch (error) {
    console.error("Failed to submit personal info:", error);
    return false;
  }
};

export const submitLegalInfo = async (formData) => {
  const response = await fetch(`${apiUrl}/api/v1/register/legal/info`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
};

export const submitLegalInfoCatalog = async (formData) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/register/betterware/legal/info`,
      {
        method: "POST",
        body: formData,
      }
    );
    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(
        `HTTP error! Status: ${response.status} - ${errorDetails}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to submit personal info:", error);
    return false;
  }
};

export const submitAditionalLegalInfo = async (formData) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/register/additional/legal/info`,
      {
        method: "POST",
        body: formData,
      }
    );
    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(
        `HTTP error! Status: ${response.status} - ${errorDetails}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to submit personal info:", error);
    return false;
  }
};

export const submitClabeInfoCatalog = async (formData) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/register/betterware/clabe`, {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: JSON.stringify(formData),
    });
    console.log(JSON.stringify(formData));

    if (!response.ok) {
      const errorDetails = await response.text();
      console.error(
        `HTTP error! Status: ${response.status}, Response: ${errorDetails}`
      );
      throw new Error(
        `HTTP error! Status: ${response.status} - ${errorDetails}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to submit Clabe info:", error);
    return false;
  }
};
