import { useState, useEffect, useCallback } from "react";
import { getUserProducts, getCatalogProducts } from "../../services/productService";

export const useCategoryProducts = (name, catalog, phone) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchProducts = useCallback(async (page) => {
    setLoading(true);
    try {
      let data;
      if (catalog) {
        data = await getCatalogProducts(catalog, name, phone, page, 20); 
      } else if (phone) {
        data = await getUserProducts(phone, name, page, 20);
      }
      setProducts(prevProducts => {
        const newProducts = data.products.filter(
          newProduct => !prevProducts.some(prevProduct => prevProduct.id === newProduct.id)
        );
        return [...prevProducts, ...newProducts];
      });
      setHasMore(data.products.length > 0);
    } catch (error) {
      console.error("Failed to fetch category products:", error);
      setError("Failed to fetch products");
    } finally {
      setLoading(false);
    }
  }, [catalog, name, phone]);

  useEffect(() => {
    fetchProducts(page);
  }, [fetchProducts, page]);

  const resetProducts = useCallback(() => {
    setProducts([]);
    setPage(1);
    setHasMore(false);
  }, []);

  return { products, loading, error, setPage, hasMore, resetProducts };
};