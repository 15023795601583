import { useState, useEffect } from "react";
import { validatePersonalInfo } from "../assets/utils/UtiltiesFunctions.js";
import { passwordsMatch } from "../assets/utils/validators.js";
import { submitTextData } from "../services/registrationService.js";

export const usePersonalInfo = (phone, onPersonalInfoSubmit) => {
  const [personalInfo, setPersonalInfo] = useState({
      userWhatsApp: phone || "",
      userEmail: "",
      password: "",
      confirmPassword: "",
      registerCode: "",
  });
  const [errors, setErrors] = useState({});
  const [validatedFields, setValidatedFields] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target;

      setPersonalInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
      validateField(name, value);
  };

  const validateField = (name, value) => {
      let error = null;
      if (name === "userWhatsApp") {
        value = value.replace(/\D/g, "").slice(0, 10);
      }

      if (name === "confirmPassword") {
          if (!passwordsMatch(personalInfo.password, value)) {
              error = "Las contraseñas no coinciden.";
          }
      } else {
          const fieldErrors = validatePersonalInfo({ [name]: value });
          error = fieldErrors[name];
      }

      setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error,
      }));

      setValidatedFields((prevValidated) => ({
          ...prevValidated,
          [name]: !error,
      }));
  };

  const handleBlur = (e) => {
      const { name, value } = e.target;
      if (name === "registerCode" && value === "") return;
      validateField(name, value);
  };

  useEffect(() => {
      const interval = setInterval(() => {
          const validationErrors = validatePersonalInfo(personalInfo);

          const newValidatedFields = Object.keys(personalInfo).reduce(
              (acc, key) => {
                  if (!validationErrors[key] && personalInfo[key]) {
                      acc[key] = true;
                  }
                  return acc;
              },
              {}
          );

          setValidatedFields(newValidatedFields);
      }, 200);

      return () => clearInterval(interval);
  }, [personalInfo]);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validatePersonalInfo(personalInfo);
    setIsLoading(true);

    if (Object.keys(validationErrors).length === 0) {
      const submitted = await submitTextData(personalInfo);
      if (submitted) {
        setIsLoading(false);
        setIsSuccess(true);
        setTimeout(() => {
          onPersonalInfoSubmit(personalInfo);
          setIsSuccess(false);
        }, 1500);
      } else {
        console.log("Submission failed");
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  return { personalInfo, errors, validatedFields, handleChange, handleBlur, handleSubmit, togglePasswordVisibility, showPassword, isLoading, isSuccess };
};