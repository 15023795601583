import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProductsCatalog } from '../services/userData.js';
import { deleteProduct, patchProduct } from '../services/userUpdateData.js';
import { useNavigate } from 'react-router-dom';

const useProductForm = (phone) => {
  const { productId } = useParams();
  const tokenData = localStorage.getItem('accessToken');
  const { token } = JSON.parse(tokenData);
  const navigate = useNavigate();

  const initialProductState = {
    productName: '',
    productDescription: '',
    productPrice: '',
    productOfferPrice: '',
    productCategory: '',
    productInventory: '',
    productId: '',
    size: '',
    color: 'NA',
    status: 'true',
  };

  const [product, setProduct] = useState(initialProductState);
  const [images, setImages] = useState({ large: null, small1: null, small2: null, small3: null, small4: null });
  const [previewImages, setPreviewImages] = useState({ large: '', small1: '', small2: '', small3: '', small4: '' });
  const [foundProduct, setFoundProduct] = useState(null);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errors, setError] = useState(''); 

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productData = await getProductsCatalog(phone);
        if (productId !== 'new') {
          const foundProduct = productData.find((p) => p.id === productId);
          if (foundProduct) {
            console.log(foundProduct)
            const displayCategory = foundProduct.productCategory === 'Feed' ? 'Sin categoría' : foundProduct.productCategory;

            setProduct({
              ...foundProduct,
              productCategory: displayCategory,
              status: foundProduct.active ? 'true' : 'false',
            });

            setPreviewImages({
              large: foundProduct.productImages?.[0] || '',
              small1: foundProduct.productImages?.[1] || '',
              small2: foundProduct.productImages?.[2] || '',
              small3: foundProduct.productImages?.[3] || '',
              small4: foundProduct.productImages?.[4] || '',
            });

            setImages({
              large: null,
              small1: null,
              small2: null,
              small3: null,
              small4: null,
            });

            setFoundProduct(foundProduct);
            setIsButtonDisabled(true);
          }
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [phone, productId]);

  const checkIfFormUnchanged = (updatedProduct, initialProduct) => {
    const relevantKeys = Object.keys(initialProductState);

    return relevantKeys.every((key) => updatedProduct[key] === initialProduct[key]);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedProduct = {
      ...product,
      [name]: type === 'checkbox' ? checked : value,
    };
    setProduct(updatedProduct);

    const unchanged = checkIfFormUnchanged(updatedProduct, foundProduct || initialProductState);
    setIsButtonDisabled(unchanged);

    console.log('Updated product:', updatedProduct);
  };

  const handleImageChange = (name, file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedImages = {
          ...images,
          [name]: file,
        };
        setImages(updatedImages);

        const objectURL = URL.createObjectURL(file);
        setPreviewImages((prevPreviews) => ({
          ...prevPreviews,
          [name]: objectURL,
        }));

        const unchanged = checkIfFormUnchanged(product, foundProduct || initialProductState);
        setIsButtonDisabled(unchanged);

        console.log('Updated images:', updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const filterProductData = (product) => {
    const filteredProduct = {};

    Object.keys(initialProductState).forEach((key) => {
      if (product.hasOwnProperty(key)) {
        filteredProduct[key] = product[key];
      }
    });
    if (!product.productId || product.productId === foundProduct?.productId) {
      filteredProduct.productId = '';
    }

    // Handle "Sin categoría" scenario before submission
    if (filteredProduct.productCategory === 'Sin categoría') {
      filteredProduct.productCategory = '';
    }

    return filteredProduct;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(''); 
  
    const formData = new FormData();
    formData.append('userWhatsApp', phone);
    formData.append('catalog', 'aida_link');
  
    const filteredProduct = filterProductData(product);
  
    Object.entries(filteredProduct).forEach(([key, value]) => {
      formData.append(key, value);
    });
  
    Object.entries(previewImages).forEach(([key, imageURL], index) => {
      const image = images[key];
  
      if (image instanceof File) {
        formData.append(`image_${index}`, image);
      } else if (imageURL && !image) {
        formData.append(`image_${index}`, imageURL);
      }
    });
  
    if (foundProduct) {
      formData.append('id', foundProduct.id);
    }
  
    try {
      const result = await patchProduct(token, formData, phone);
      console.log('Product submitted successfully:', result);
      setSuccess(true);
      setIsButtonDisabled(true);
      setTimeout(() => setSuccess(false), 1500);
      return true; 
    } catch (error) {
      if (error.message.includes('SKU already exists')) {
        setError('El id del producto ya existe. Por favor selecciona otro'); 
      } else {
        setError('¡Hubo un problema al intentar actualizar tu producto! Intenta más tarde.');
      }
      console.error('Error submitting product:', error);
      return false; 
    } finally {
      setLoading(false);
    }
  };  

  const handleDeleteClick = async () => {
    if (window.confirm('¿Estás seguro que quieres eliminar el producto?')) {
      try {
        await deleteProduct(phone, product.id);
        alert('Producto eliminado exitosamente');
        navigate(-1);
      } catch (error) {
        alert('Error al eliminar producto');
      }
    }
  };

  const handleReset = () => {
    if (foundProduct) {
      const displayCategory = foundProduct.productCategory === 'Feed' ? 'Sin categoría' : foundProduct.productCategory;

      setProduct({
        ...foundProduct,
        productCategory: displayCategory,
        status: foundProduct.active ? 'true' : 'false',
      });
      setPreviewImages({
        large: foundProduct.productImages?.[0] || '',
        small1: foundProduct.productImages?.[1] || '',
        small2: foundProduct.productImages?.[2] || '',
        small3: foundProduct.productImages?.[3] || '',
        small4: foundProduct.productImages?.[4] || '',
      });
      setImages({
        large: null,
        small1: null,
        small2: null,
        small3: null,
        small4: null,
      });
    } else {
      setProduct(initialProductState);
      setPreviewImages({ large: '', small1: '', small2: '', small3: '', small4: '' });
      setImages({ large: null, small1: null, small2: null, small3: null, small4: null });
    }
    setIsButtonDisabled(true);
  };

  return {
    product,
    previewImages,
    loading,
    success,
    isButtonDisabled,
    errors, 
    handleInputChange,
    handleImageChange,
    handleSubmit,
    handleDeleteClick,
    setProduct,
    handleReset
  };
};

export default useProductForm;
