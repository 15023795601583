import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CsvFile } from './pages/CsvFile';
import Settings from './pages/Settings';
import UserProductServices from './pages/settingsPages/UserProductServices';
import UserBussines from './pages/settingsPages/UserBussines';
import UserServices from './pages/settingsPages/UserServices';
import { useParamsContext } from '../context/ParamsProvider';
import ReturnButton from './components/utils/ReturnButton';
import UserCategory from './pages/settingsPages/UserCategory';
import UserProducts from './pages/settingsPages/UserProducts';
import UserCategoryList from './pages/settingsPages/UserCategoryList';
import ProductUploadForm from './pages/settingsPages/ProductUploadForm';
import styles from './Dashboard.module.css';
import NavBar from './components/navigation/NavBar';
import { useUserContext } from '../context/UserProvider';

const Dashboard = () => {
  const { phone } = useParamsContext();
  const { userNameData, fetchUserData } = useUserContext();
  const location = useLocation();
  
  useEffect(() => {
    fetchUserData(phone); 
  }, [phone, fetchUserData]);

  console.log(userNameData);

  return (
    <div className={styles.dashboardContainer}>
      <NavBar userNameData={userNameData} phone={phone}/>
      {!/^\/dashboard\/settings\/?(\?phone=.*)?$/.test(location.pathname) && <ReturnButton />}
      <Routes location={location} key={location.pathname}>
        <Route path="csv/*" element={<CsvFile />} />
        <Route path="settings/" element={<Settings userNameData={userNameData} phone={phone}/>} />
        <Route path="/my-bussines" element={<UserBussines userNameData={userNameData} phone={phone}/>} />
        <Route path="/services" element={<UserServices userNameData={userNameData} phone={phone}/>} />
        <Route path="/shop" element={<UserProductServices userNameData={userNameData} phone={phone}/>} />
        <Route path='/category' element={<UserCategory phone={phone}/>}/>
        <Route path='/products-services' element={<UserProducts phone={phone}/>}/>
        <Route path='/categorylist' element={<UserCategoryList phone={phone}/>}/>
        <Route path='/product-upload/' element={<ProductUploadForm phone={phone}/>}/>
        <Route path='/product-upload/:productId' element={<ProductUploadForm  phone={phone}/>}/>
        <Route path='/category/:categoryName' element={<UserCategory phone={phone}/>} />
      </Routes>
    </div>
  );
};

export default Dashboard;
