const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API;

export const getUserData = async (phone) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/dashboard/user/data/${phone}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch business info failed:", error);
    throw error;
  }
};

export const getCategoryData = async (phone) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/products/dashboard/category/aida_link?phone=${phone}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch products by category failed:", error);
    throw error;
  }
};

export const getProductsCatalog = async (phone) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/dashboard/get/products/aida_link/${phone}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch product catalog failed:", error);
    throw error;
  }
};