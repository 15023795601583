import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import Spinner from "../../../assets/animations/LoadinSpinner.json";
import Checkmark from "../../../assets/animations/SuccessSpinner.json";
import styles from './ContinueButton.module.css';

const ContinueButton = ({ disabled, isLoading = false, isSuccess = false,}) => {
  return (
    <>
      <button
        className={styles.confirmButtonMobile}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <Lottie animationData={Spinner} style={{ width: 22, height: 22 }} />
        ) : isSuccess ? (
          <Lottie animationData={Checkmark} style={{ width: 22, height: 22 }} />
        ) : (
          "Confirmar"
        )}
      </button>
      <button
        className={styles.confirmButtonDesktop}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <Lottie animationData={Spinner} style={{ width: 16, height: 16 }} />
        ) : isSuccess ? (
          <Lottie animationData={Checkmark} style={{ width: 16, height: 16 }} />
        ) : (
          "Guardar"
        )}
      </button>
    </>
  );
};

ContinueButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

export default ContinueButton;
