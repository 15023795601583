import { useState } from 'react';

export const useFormData = () => {
  const [formData, setFormData] = useState({
    productUpload: [],
  });

  const handleFormData = (newData) => {
    console.log("Updating form data with:", newData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData
    }));
  };

  return { formData, handleFormData };
};