import React, { useContext, useRef, useCallback } from "react";
import styles from "./ProductGrid.module.css";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { ReactComponent as OfferTag } from "../../../assets/icons/OfferTagIcon.svg";

function ProductGrid({ products = [], onLoadMore, catalogParam }) {
  const { dispatch } = useContext(CartContext);
  const observer = useRef();

  const lastProductElementRef = useCallback(
    (node) => {
      if (onLoadMore) {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            onLoadMore();
          }
        });
        if (node) observer.current.observe(node);
      }
    },
    [onLoadMore]
  );

  const addToCart = (product) => {
    dispatch({ type: "ADD_ITEM", payload: product });
  };

  if (!products.length) {
    return <div>No products available</div>;
  }

  return (
    <div className={styles.productGrid}>
      {products.map((product, index) => {
        const isLastElement = index === products.length - 1;
        const productIdOrId = catalogParam === "aida_link" ? product.id : product.productId;

        return (
          <div
            key={index}
            className={styles.productCard}
            ref={isLastElement ? lastProductElementRef : null}
          >
            <div className={styles.offerTag}>
              <Link
                to={`/shop/product/${productIdOrId}`}
                state={{ product }}
              >
                <img
                  src={product.productImages && product.productImages[0]}
                  alt={product.productName || "Product"}
                  className={styles.productImage}
                  loading="lazy"
                />
              </Link>
              {product.productOfferInstruction &&
                product.productOfferInstruction.length > 0 && (
                  <span className={styles.offerLabel}>
                    {product.productOfferInstruction.length > 0 ? (
                      <OfferTag className={styles.OfferTagIcon} />
                    ) : (
                      ""
                    )}
                  </span>
                )}
            </div>
            <section className={styles.productInfo}>
              <h3>
                {"$" + (product.productPrice || "0")}
                {product.productOfferPrice && (
                  <>
                    {" "}
                    <span className={styles.productPriceInstructions}>
                      o llévatelo por
                    </span>{" "}
                    <span className={styles.productOfferPrice}>
                      {"$" + product.productOfferPrice}
                    </span>
                  </>
                )}
              </h3>
              <h2>{product.productName || "Unnamed Product"}</h2>
            </section>
            <div className={styles.productButtonContainer}>
              <button
                onClick={() => addToCart(product)}
                className={styles.addToCartButton}
              >
                Agregar
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProductGrid;
