import React, { useContext, useState, useRef, useEffect } from "react";
import { CartContext } from "../../contexts/CartContext";
import Cart from "../Cart/Cart";
import { ReactComponent as CartIcon } from "../../../assets/icons/cartIcon.svg";
import { ReactComponent as AddedCartIcon } from "../../../assets/icons/addedToCartIcon.svg";
import styles from "./CartToggle.module.css";

const CartToggle = ({ phone, catalogParam }) => {
  const { state, dispatch } = useContext(CartContext);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const cartRef = useRef(null);

  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const handleCloseCart = () => {
    setIsCartVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsCartVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartRef]);

  useEffect(() => {
    if (state.alert) {
      setTimeout(() => {
        dispatch({ type: "CLEAR_ALERT" });
      }, 3000);
    }
  }, [state.alert, dispatch]);

  const totalItems = Math.round(
    state.items.reduce((acc, item) => acc + item.quantity, 0)
  );
  const subtotal = state.total;

  return (
    <div className={styles.cartButtonContainer} ref={cartRef}>
      <button onClick={toggleCart} className={styles.cartButton}>
        <CartIcon
          className={`${styles.cartIcon} ${
            totalItems > 0 ? styles.cartIconActive : ""
          }`}
        />
        {totalItems > 0 && (
          <span className={styles.cartCounter}>{totalItems}</span>
        )}
      </button>
      {isCartVisible && <Cart phone={phone} catalogParam={catalogParam} onClose={handleCloseCart} />}
      {totalItems > 0 && (
        <div className={styles.cartFooter}>
          <div className={styles.cartInfo}>
            <span className={styles.cartProducts}>Productos: {totalItems}</span>
            <span className={styles.cartSubtotal}>
              Subtotal: ${subtotal} MXN
            </span>
          </div>
          <button className={styles.mobileCheckoutButton} onClick={toggleCart}>
            Pagar
          </button>
        </div>
      )}
      {state.alert && (
        <div className={styles.alert}>
          <AddedCartIcon />
          Artículo añadido al carrito
        </div>
      )}
    </div>
  );
};

export default CartToggle;
