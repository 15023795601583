import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import styles from "../Carousel/Product.module.css";
import { ReactComponent as OfferTag } from "../../../assets/icons/OfferTagIcon.svg";

const Product = ({ product, index, businessColor, catalogParam }) => {
  const { dispatch } = useContext(CartContext);

  const handleAddToCart = (product) => {
    dispatch({
      type: "ADD_ITEM",
      payload: product,
    });
  };

  const imageContainerSpanStyle = {
    top: '5px',
    right: '25px',
    position: 'absolute',
    color: 'white',
    borderRadius: '100px',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: catalogParam === "aida_link" ? businessColor : '#FF6330',
  };

  const productIdOrId = catalogParam === "aida_link" ? product.id : product.productId;

  return (
    <div
      key={product.productId || product.id || index}
      className={styles.productCard}
    >
      <Link to={`/shop/product/${productIdOrId}`}>
        <div className={styles.productImageContainer}>
          <img
            src={product.productImages && product.productImages[0]}
            alt={product.productName || "Product Image"}
            className={styles.productImage}
            loading="lazy"
          />
          {product.productOfferInstruction &&
            product.productOfferInstruction.length > 0 && (
              <span style={imageContainerSpanStyle}>
                {product.productOfferInstruction.length > 0 ? (
                  <OfferTag className={styles.OfferTagIcon} />
                ) : (
                  ""
                )}
              </span>
            )}
        </div>
        <div className={styles.productDetails}>
          <h2 className={styles.productName}>
            {product.productName || "Unnamed Product"}
          </h2>
          <p className={styles.productPriceCarousel}>
            {"$" + (product.productPrice || "0")}{" "}
            {product.productOfferPrice && (
              <>
                {" "}
                <span className={styles.productPriceInstructions}>
                  o llévatelo por
                </span>{" "}
                <span className={styles.productOfferPrice}>
                  {"$" + product.productOfferPrice}
                </span>
              </>
            )}
          </p>
        </div>
      </Link>
      <button
        onClick={() => handleAddToCart(product)}
        className={styles.addToCartButton}
      >
        Agregar
      </button>
    </div>
  );
};

export default Product;
