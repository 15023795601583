import { useState } from "react";
import { createCatalog } from "../services/registrationService";

export const useCreateCatalog = (
  phone,
  onCatalogSelection,
  setBusinessType
) => {
  const [role, setRole] = useState("associate");
  const [isLoading, setIsLoading] = useState(false);

  const [catalogInfo, setCatalogInfo] = useState({
    userWhatsApp: phone,
    businessType: "betterware",
    associateCode: "",
    businessRange: "associate",
    businessZone: "",
  });

  const handleCatalogClick = (selectedCatalog) => {
    setCatalogInfo((prevState) => ({
      ...prevState,
      businessType: selectedCatalog,
    }));
  };

  const handleButtonClick = (selectedRole) => {
    setRole(selectedRole);
    setCatalogInfo((prevState) => ({
      ...prevState,
      businessRange: selectedRole,
      associateCode:
        selectedRole === "associate" ? prevState.associateCode : "",
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCatalogInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const submitedInfo = await createCatalog(catalogInfo);
      onCatalogSelection(submitedInfo);
      setIsLoading(false);
      setBusinessType(catalogInfo.businessType);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const getPlaceholder = () => {
    switch (role) {
      case "associate":
        return "Número Asociado";
      case "distributor":
        return "Número Distribuidor";
      default:
        return "";
    }
  };

  return {
    handleCatalogClick,
    handleButtonClick,
    handleInputChange,
    handleSubmit,
    getPlaceholder,
    role,
    isLoading,
  };
};

export default useCreateCatalog;
