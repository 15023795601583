const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API;

export const patchBasicBusinessInfo = async (token, formData) => {
  try {
      console.log(token)
      const response = await fetch(
          `${apiUrl}/api/v1/dashboard/basic/business/info?catalog=aida_link`,
          {
          method: 'PATCH',
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: formData,
          }
      );

      if (!response.ok) {
          throw new Error("Failed to update business info");
      }
      const data = await response.json();
      return data;
      } catch (error) {
      console.error("Update business info failed:", error);
      throw error;
      }
  };

export const patchBusinessInfo = async (token, formData) => {
  try {
      const response = await fetch(
        `${apiUrl}/api/v1/dashboard/business/info?catalog=aida_link`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to update business info");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Update business info failed:", error);
      throw error;
    }
};

export const patchCategory = async (token, formData) => {
  try {
      const response = await fetch(
        `${apiUrl}/api/v1/dashboard/category/info?catalog=aida_link`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to update business info");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Update business info failed:", error);
      throw error;
    }
};  

export const patchProduct = async (token, formData) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/v1/dashboard/register/product/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (!response.ok) {
      const errorData = await response.json(); 
      const errorMessage = errorData.description || "Failed to update product";
      throw new Error(errorMessage); 
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Update product failed:", error);
    throw error; 
  }
};


export const deleteProduct = async (phone, productId) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/dashboard/remove/product/${phone}/${productId}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Failed to delete the product');
    }
    return await response.json();
  } catch (error) {
    console.error('Error deleting product:', error);
    throw error;
  }
};

export const uploadProductCSV = async (phone, file) => {
  try {
    const formData = new FormData();
    formData.append('userWhatsApp', phone);
    formData.append('file', file);

    const response = await fetch(`${apiUrl}/api/v1/dashboard/register/csv/product`, {
      method: 'POST', 
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload the file');
    }

    return await response.json();
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const catalogSuscription = async (phone, action) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/dashboard/register/catalog/subscription`, {
      method: 'POST',
      headers: {
        "Content-Type": "text/plain"
      },
      body: JSON.stringify({
        action: action,
        phone: phone,
        businessType: "aida_link"
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update catalog subscription');
    }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
