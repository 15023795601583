import React from 'react'
import ContentLoader from 'react-content-loader'
import HeaderLoader from './HeaderLoader'
import styles from './HomePageLoader.module.css'

export const HomePageLoader = (props) => {
  return (
    <div className={styles.container}>
      <ContentLoader viewBox="0 0 400 400" height={400} width="100%" {...props} backgroundColor='#E8EAED' foregroundColor='#f5f5f5'>
        <circle cx="330" cy="200" r="50" />
        <rect x="25" y="230" rx="4" ry="4" width="100" height="23" />
        <rect x="25" y="270" rx="5" ry="5" width="240" height="10" />
        <rect x="25" y="287" rx="5" ry="5" width="240" height="10" />
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="200" />
      </ContentLoader>
      <ContentLoader viewBox="0 0 400 100" height={100} width={400} {...props} backgroundColor='#E8EAED' foregroundColor='#f5f5f5'>
        <rect x="20" y="30" rx="15" ry="15" width="300" height="35" />
        <rect x="330" y="30" rx="15" ry="15" width="40" height="35" />
      </ContentLoader>
      <HeaderLoader/>
    </div>
  )
}
