import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/SignIn.module.css';
import Bottom from '../../assets/Images/BottomShape.svg';
import logo from '../../logo.svg';
import { useParamsContext } from '../../context/ParamsProvider';
import { forgotPassword, subtmitLogin } from '../../services/authService';
import { fetchRegistrationStep } from '../../services/registrationService';

export const SignIn = () => {
  const { phone } = useParamsContext();
  const [password, setPassword] = useState('');
  const [ name, setName ] = useState()

  useEffect(() => {
    const initStep = async () => {
      try {
        const result = await fetchRegistrationStep(phone);
        setName(result.preferedName);
      } catch (error) {
        console.error('Problem fetching name');
      }
    };
    initStep();
  }, [setName, phone]);

  const handleLogin = async () => {
    try {
      const response = await subtmitLogin(phone, password);
      console.log(response); 

      if (response) {
        const expirationTime = new Date().getTime() + 60 * 60 *1000;
        const tokenData = {
          token: response.token,
          expiresAt: expirationTime,
        };
        localStorage.setItem('accessToken', JSON.stringify(tokenData)); 
        window.location.href = `/dashboard/settings?phone=${phone}`;
      } else {
        alert('Login fallido. Checa tus credenciales');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await forgotPassword(phone);
      console.log(response);
  
      if (response) {
        alert(`${response.description}`);
      } else {
        alert('No se pudo obtener tu contraseña');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };
  

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.title}>
          <img src={logo} alt="Catalogo Web Logo" className={styles.logo} />
          <h2>
            <span className={styles.greeting}>Buenas tardes,</span>
            <span className={styles.name}>{name}</span>
          </h2>
        </div>
        <div className={styles.inputContainer}>
          <p className={styles.password}>Contraseña</p>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
          <p className={styles.forgotPassword} onClick={handleForgotPassword}>Olvidé mi contraseña</p>
        </div>
        <div className={styles.buttonsContainer}>
          <button className={styles.loginButton} onClick={handleLogin}>
            Entrar
          </button>
        </div>
        <h2 className={styles.bottomTitle}>Hagamos que suceda</h2>
        <img className={styles.bottomShape} src={Bottom} alt="bottom shape" />
      </div>
    </div>
  );
};