import React from "react";
import CustomDropdown from "../../components/pages_components/CustomDropdown.js";
import signUpstyles from "../../SignUpForm.module.css";
import businessInfoStyles from "../../assets/styles/BusinessInfoForm.module.css";
import heroIcon from "../../assets/icons/heroIcon.svg";
import InputField from "../../components/pages_components/InputField.js";
import { submitBusinessInfo } from "../../services/registrationService";
import useBusinessInfo from "../../hooks/useBusinessInfo.js";
import ImageUploadField from "../../components/pages_components/ImageUploadField.js";
import AnimationButton from "../../components/utils/AnimationButton.js";

const styles = { ...signUpstyles, ...businessInfoStyles };

export const LinkBusinessInfo = ({ phone, onBusinessInfoSubmit }) => {
  const initialState = {
    userWhatsApp: phone,
    businessName: "",
    businessIndustry: "",
    businessDescription: "",
    businessLogo: null,
    acceptTCAndPrivacy: false,
  };
  const {
    businessInfo,
    errors,
    handleChange,
    handleSubmit,
    validatedFields,
    setBusinessInfo,
    isLoading,
    handleCheckboxChange,
    isSuccess,
  } = useBusinessInfo(onBusinessInfoSubmit, initialState, submitBusinessInfo);

  return (
    <form onSubmit={handleSubmit} method="post">
      <h2 className={styles.resourcesHeader}>Información de tu negocio</h2>
      <hr className={styles.headRule} />
      <p className={styles.resourcesHeaderText}>
        Personaliza con la imagen de tu marca
      </p>
      <div className={styles.columnDocuments}>
        <div className={styles.formFieldInfo}>
          <InputField
            label="Nombre de tu negocio o tienda"
            type="text"
            name="businessName"
            placeholder="Si no tienes un negocio, ingresa tu nombre"
            personalInfo={businessInfo}
            validatedFields={validatedFields}
            handleChange={handleChange}
          />
        </div>
        <div className={styles.formFieldInfo}>
          <InputField
            label="Descripción breve de tu negocio"
            type="text"
            name="businessDescription"
            placeholder="Ej: Venta de artesanías locales"
            personalInfo={businessInfo}
            validatedFields={validatedFields}
            handleChange={handleChange}
          />
        </div>
        <section className={styles.inputSideBySide}>
          <ImageUploadField
            id="businessLogo"
            info={businessInfo}
            validated={validatedFields}
            errors={errors}
            handleFileChange={handleChange}
            icon={heroIcon}
            label="Logo"
            labelClassName="customImageUpload"
            iconStyleProp="iconStyle2"
          />
          <div className={styles.formField}>
            <label>
              Giro
              <CustomDropdown
                value={businessInfo.businessIndustry}
                onChange={(newValue) => {
                  setBusinessInfo({
                    ...businessInfo,
                    businessIndustry: newValue,
                  });
                }}
              />
            </label>
          </div>
        </section>
        <section className={styles.formCheck}>
          <label className={styles.noticeTitle}>
            He leído, acepto y estoy de acuerdo con la{" "}
            <a
              href="https://soyaida.com/privacy-policy-shop.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              política de privacidad
            </a>{" "}
            y los{" "}
            <a
              href="https://soyaida.com/terms-conditions-shop.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              términos y condiciones.
            </a>
            <input
              type="checkbox"
              name="acceptTCAndPrivacy"
              checked={businessInfo.accounAcceptanceInput}
              onChange={handleCheckboxChange}
              required
              className={styles.checkBox}
            />
          </label>
        </section>
      </div>
      <AnimationButton isLoading={isLoading} isSuccess={isSuccess}>
        Confirmar
      </AnimationButton>
    </form>
  );
};
