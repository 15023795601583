import { useState } from 'react';

const useSocialMediaLinks = (initialLinks) => {
  const initializeSocialMediaLinks = () => {
    return initialLinks && initialLinks.length > 0
      ? initialLinks.map(link => ({
          type: link.type || '',
          link: link.link || '', 
        }))
      : [
          { type: "facebook", link: "" },
          { type: "instagram", link: "" },
          { type: "tiktok", link: "" }
        ];
  };

  const [socialMediaLinks, setSocialMediaLinks] = useState(initializeSocialMediaLinks);

  return {
    socialMediaLinks,
    setSocialMediaLinks,
  };
};

export default useSocialMediaLinks;
