import { useCallback, useState } from "react";

export const useLegalInfo = (onLegalInfoSubmit, initialState, validate, submit, submitFormData = false) => {
    const [legalInfo, setLegalInfo] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [validatedFields, setValidatedFields] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const toggleTypePerson = (type) => {
        setLegalInfo((prevState) => ({
            ...prevState,
            typePerson: type,
        }));
    };

    const validateField = useCallback((name, value) => {
        const updatedInfo = { ...legalInfo, [name]: value };

        // If validating 'clabe' or 'confirmClabe', ensure both are validated
        if (name === 'clabe' || name === 'confirmClabe') {
            updatedInfo.clabe = updatedInfo.clabe || legalInfo.clabe;
            updatedInfo.confirmClabe = updatedInfo.confirmClabe || legalInfo.confirmClabe;
        }

        const validationErrors = validate(updatedInfo);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: validationErrors[name],
            // If 'clabe' or 'confirmClabe' was validated, ensure both errors are set
            ...(name === 'clabe' || name === 'confirmClabe') && {
                clabe: validationErrors.clabe,
                confirmClabe: validationErrors.confirmClabe,
            }
        }));
        setValidatedFields(prevState => ({
            ...prevState,
            [name]: !validationErrors[name],
            // If 'clabe' or 'confirmClabe' was validated, ensure both validated states are set
            ...(name === 'clabe' || name === 'confirmClabe') && {
                clabe: !validationErrors.clabe,
                confirmClabe: !validationErrors.confirmClabe,
            }
        }));
    }, [legalInfo, validate]);

    const handleBlur = useCallback((e) => {
        const { name, value } = e.target;
        validateField(name, value);
    }, [validateField]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setLegalInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        validateField(name, value);
    }, [validateField]);

    const handleFileChange = useCallback((e) => {
        const { name, files } = e.target;
        if (files && files.length > 0) {
            const file = files[0];
            setLegalInfo(prevState => ({
                ...prevState,
                [name]: file,
            }));
            validateField(name, file);
        } else {
            console.error("No file selected");
        }
    }, [validateField]);

    const handleCheckboxChange = useCallback((e) => {
        const { name, checked } = e.target;
        setLegalInfo(prevState => ({
            ...prevState,
            [name]: checked,
        }));
        validateField(name, checked);
    }, [validateField]);

    const validateAndSetErrors = useCallback(() => {
        const validationErrors = validate(legalInfo);
        if (Object.keys(validationErrors).length === 0) {
            return true;
        } else {
            setErrors(validationErrors);
            return false;
        }
    }, [legalInfo, validate]);

    const createFormData = useCallback(() => {
        const formData = new FormData();
        Object.entries(legalInfo).forEach(([key, value]) => {
            if (value) {
                if (value instanceof File) {
                    formData.append(key, value, value.name);
                } else {
                    formData.append(key, value);
                }
            }
        });
        return formData;
    }, [legalInfo]);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        const isValid = validateAndSetErrors();
        if (isValid) {
            try {
                let responseData;
                if (submitFormData) {
                    const formData = createFormData();
                    responseData = await submit(formData);
                } else {
                    responseData = await submit(legalInfo);
                }
                setIsLoading(false);
                setIsSuccess(true);
                setTimeout(() => {
                    onLegalInfoSubmit(responseData);
                    setIsSuccess(false);
                }, 1500);
            } catch (error) {
                console.error("Failed to submit legal info:", error);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    return {
        handleFileChange,
        handleSubmit,
        errors,
        validatedFields,
        legalInfo,
        setLegalInfo,
        toggleTypePerson,
        handleInputChange,
        handleBlur,
        handleCheckboxChange,
        isLoading,
        isSuccess
    };
}