import React from 'react'
import logo from "../../logo.svg";
import styles from '../../assets/styles/Header.module.css'

const Header = ({ name }) => {
    return (
      <div className={styles.header}>
        <div className={styles.banner}>
          <img src={logo} alt="Catalogo Web Logo" className={styles.logo} />
        </div>
        <h2>Hagamos que suceda</h2>
      </div>
    );
  }

export default Header
