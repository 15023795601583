import PersonalInfoForm from "../form_pages/shop/PersonalInfoForm";
import styles from "../SignUpForm.module.css";
import CreateOrSubscribe from "../form_pages/catalog/CreateOrSubscribe";
import CatalogBusinessInfo from "../form_pages/catalog/CatalogBusinessInfo";
import CreateCatalog from "../form_pages/catalog/CreateCatalog";
import CatalogWelcome from "../form_pages/catalog/CatalogWelcome";
import LinkLegalInfo from "../form_pages/aidaLink/LinkLegalInfo";
import { LinkBusinessInfo } from "../form_pages/aidaLink/LinkBusinessInfo";
import AidaComponent from "./SignUpForm/AidaComponent";
import Header from "./SignUpForm/Header";
import { ReturnButton } from "./utils/ReturnButton";
import { AnimatePresence, motion } from "framer-motion";

export const stepConfig = (
  handleFormData,
  nextStep,
  prevStep,
  phone,
  showCreateCatalog,
  setShowCreateCatalog,
  aidaPhone,
  preferedName,
  step
) => ({
  USER_INFO: () => (
    <>
      <AidaComponent copy="La solución #1 para cobrar, vender y crecer desde WhatsApp." />
      <PersonalInfoForm
        onPersonalInfoSubmit={(data) => {
          handleFormData({ personalInfo: data });
          nextStep("LEGAL_INFO");
        }}
        phone={phone}
      />
    </>
  ),
  LEGAL_INFO: () => (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 1, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 1, y: -200 }}
          transition={{ duration: 0.5 }}
          key={step}
        >
          <LinkLegalInfo
            onLegalInfoSubmit={(data) => {
              handleFormData(data);
              nextStep("BUSINESS_INFO")
            }}
            phone={phone}
            prevStep={prevStep}
          />
        </motion.div>
      </AnimatePresence>
    </>
  ),
  BUSINESS_INFO: () => (
    <>
      <AnimatePresence mode="wait">
        <ReturnButton prevStep={prevStep} />
        <motion.div
          initial={{ opacity: 1, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 1, y: -200 }}
          transition={{ duration: 0.5 }}
          key={step}
        >
          <LinkBusinessInfo
            onBusinessInfoSubmit={(data) => {
              handleFormData(data);
              nextStep("REGISTERED_USER");
            }}
            phone={phone}
            prevStep={prevStep}
          />
        </motion.div>
      </AnimatePresence>
    </>
  ),
  REGISTERED_USER: () => (
    <CatalogWelcome
      onAccept={() => {
        // Logic for accepting the registration
      }}
      name={preferedName}
      aidaPhone={aidaPhone}
    />
  ),
  CATALOG_SUBSCRIPTION_ENABLED: () =>
    !showCreateCatalog ? (
      <>
        <AidaComponent copy="Por el momento, solo disponible para vendedoras y distribuidoras ya afiliadas a Bettereware." />
        <CreateOrSubscribe
          onSubscriptionSubmit={() => {
            setShowCreateCatalog(true);
          }}
        />
      </>
    ) : (
      <CreateCatalog
        phone={phone}
        onCatalogSelection={(data) => {
          handleFormData({ catalogType: data });
          nextStep("BUSINESS_INFO_CATALOG");
        }}
        prevStep={prevStep}
      />
    ),
  BUSINESS_INFO_CATALOG: () => (
    <>
      <ReturnButton prevStep={prevStep} />
      <CatalogBusinessInfo
        onBusinessInfoSubmit={(data) => {
          handleFormData(data);
          nextStep("");
        }}
        phone={phone}
        prevStep={prevStep}
      />
    </>
  ),
  default: () => (
    <>
      <Header />
        <a
          href={`https://api.whatsapp.com/send/?phone=${aidaPhone}&text=&type=phone_number&app_absent=0`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button type="button" className={styles.continueButton}>Chat con aida</button>
        </a>
    </>
  ),
});
