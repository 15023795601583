import React from "react";
import styles from "./Header.module.css";
import defaultLogo from "../../../assets/Images/logo512.png";
import FacebookIcon from "../../../assets/icons/facebook-icon.svg";
import InstagramIcon from "../../../assets/icons/instagram-icon.svg";
import TiktokIcon from "../../../assets/icons/tiktok-icon.svg";

function Header({ businessInfo }) {
  const renderSocialLinks = (socialMediaLinks) => {
    if (!socialMediaLinks || socialMediaLinks.length === 0) {
      return null;
    }

    return socialMediaLinks.map((link) => {
      const { type, link: url } = link;
      if (!url) {
        return null;
      }

      let iconSrc;
      switch (type) {
        case "facebook":
          iconSrc = FacebookIcon;
          break;
        case "instagram":
          iconSrc = InstagramIcon;
          break;
        case "tiktok":
          iconSrc = TiktokIcon;
          break;
        default:
          return null;
      }

      return (
        <button
          key={url}
          className={styles.socialButton}
          onClick={() => window.open(url, "_blank")}
        >
          <img src={iconSrc} alt={`${type} icon`} />
        </button>
      );
    });
  };

  return (
    <header className={styles.header}>
      <div className={styles.bannerContainer}>
        <div className={styles.socialMediaLinksContainer}>
          <div className={styles.socialMediaLinks}>
            {businessInfo && renderSocialLinks(businessInfo.socialMediaLinks)}
          </div>
        </div>
        <img
          src={businessInfo?.businessHeroBanner}
          alt="Banner"
          className={styles.heroBanner}
        />
      </div>
      <div className={styles.containerSideBySide}>
        <div className={styles.headerContent}>
          <div className={styles.businessInfo}>
            <h1>{businessInfo?.businessName}</h1>
            <p>{businessInfo?.businessDescription}</p>
          </div>
        </div>
        <div className={styles.logoAndSocialContainer}>
          <img
            src={businessInfo?.businessLogo ?? defaultLogo}
            alt={`${businessInfo?.businessName ?? "Business"} Logo`}
            className={styles.logo}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
