import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import Shop from './shop/Shop';
import Dashboard from './dashboard/Dashboard';
import { ParamsProvider } from './context/ParamsProvider';
import './global-styles.css';
import { SignIn } from './components/auth/SignIn';
import PrivateRoute from './components/auth/PrivateRoute';
import { useAuth } from './hooks/useAuth';
import { UserProvider } from './context/UserProvider';

function App() {
  useAuth(); 

  return (
    <ParamsProvider>
      <UserProvider>
        <Routes>
          <Route path="/register/*" element={<SignUpForm />} />
          <Route path="/shop/*" element={<Shop />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/signin/*" element={<SignIn />} />
        </Routes>
      </UserProvider>
    </ParamsProvider>
  );
}

export default App;