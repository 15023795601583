import React, { createContext, useContext, useEffect} from 'react';

const ParamsContext = createContext();

export const useParamsContext = () => useContext(ParamsContext);

const PARAM_KEY_PHONE = 'phone';
const PARAM_KEY_CATALOG = 'catalog';
const EXPIRY_TIME = 60 * 60 * 2000;

const getParamsFromUrlOrStorage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const phoneFromUrl = urlParams.get(PARAM_KEY_PHONE);
  const catalogFromUrl = urlParams.get(PARAM_KEY_CATALOG);

  const phone = phoneFromUrl || localStorage.getItem(PARAM_KEY_PHONE);
  const catalogParam = catalogFromUrl || localStorage.getItem(PARAM_KEY_CATALOG);

  return { phone, catalogParam };
};

const saveParamsToStorage = (phone, catalogParam) => {
  if (phone) localStorage.setItem(PARAM_KEY_PHONE, phone);
  if (catalogParam) localStorage.setItem(PARAM_KEY_CATALOG, catalogParam);
};

const clearParamsFromStorage = () => {
  localStorage.removeItem(PARAM_KEY_PHONE);
  localStorage.removeItem(PARAM_KEY_CATALOG);
};

export const ParamsProvider = ({ children }) => {
  const params = getParamsFromUrlOrStorage();

  useEffect(() => {
    saveParamsToStorage(params.phone, params.catalogParam);

    const timer = setTimeout(() => {
      clearParamsFromStorage();
    }, EXPIRY_TIME);

    return () => clearTimeout(timer);
  }, [params]);

  return (
    <ParamsContext.Provider value={params}>
      {children}
    </ParamsContext.Provider>
  );
};