import React from 'react'
import "../../assets/styles/base.css";
import signUpstyles from "../../SignUpForm.module.css";
import businessInfoStyles from "../../assets/styles/BusinessInfoForm.module.css";
import InputField from '../../components/pages_components/InputField';
import useBusinessInfo from '../../hooks/useBusinessInfo';
import CelImage from '../../assets/Images/Catalog-Exapmle-Larger.png'
import { submitBusinessInfoCatalog } from '../../services/registrationService';
import AnimationButton from '../../components/utils/AnimationButton';
import SocialMediaList from '../../components/pages_components/SocialMediaList';

const styles = { ...signUpstyles, ...businessInfoStyles };

const CatalogPersonalization = ({ onBusinessInfoSubmit, phone }) => {
    const initialState = {
      userWhatsApp: phone,
      businessName: "",
      businessLogo: null,
      socialMediaLinks: [
        { type: "facebook", link: "" },
        { type: "instagram", link: "" },
        { type: "tiktok", link: "" },
      ],
    }
    const { businessInfo, errors, handleChange, handleSubmit, validatedFields, isLoading } = useBusinessInfo( onBusinessInfoSubmit, initialState, submitBusinessInfoCatalog);

  return (
  <form onSubmit={handleSubmit} method="post">
    <h2 className={styles.resourcesHeader}>Personalizacion de catálogo</h2>
    <hr className={styles.headRule}/>
    <p className={styles.resourcesHeaderText}>Datos que se veran en tu catálogo.</p>
    <div className={styles.columnDocuments}>
      <section className={styles.inputSideBySide}>
        <div className={styles.formField}>
          <InputField
          label="¿Cómo se llamara tu tienda?:"
          type="text"
          name="businessName"
          placeholder="E.j: Juanita Ramírez"
          personalInfo={businessInfo}
          validatedFields={validatedFields} 
          handleChange={handleChange} 
          errors={errors}
        />
        </div>
      </section>
      <section className={styles.inputSideBySideImg}> 
        <SocialMediaList
          businessInfo={businessInfo}
          handleChange={handleChange}
          validatedFields={validatedFields}
          errors={errors}
        />
        <img src={CelImage} className={styles.personalizationImg} alt="imagen celular"/>
      </section>
    </div>
    <AnimationButton isLoading={isLoading}>
      Confirmar
    </AnimationButton>
  </form>
  )
}
export default CatalogPersonalization;