import React from "react";
import signUpstyles from "../../SignUpForm.module.css";
import legalInfostyles from "../../assets/styles/LegalInfoForm.module.css";
import { useLegalInfo } from "../../hooks/useLegalInfo";
import BankDropdown from "../../components/pages_components/BankDropdown";
import ImageUploadField from "../../components/pages_components/ImageUploadField";
import { validateLegalInfoLink } from "../../assets/utils/UtiltiesFunctions";
import { submitLegalInfo } from "../../services/registrationService";
import InputField from "../../components/pages_components/InputField";
import ineFrontIcon from "../../assets/icons/ineFrontIcon.svg";
import ineBackIcon from "../../assets/icons/ineBackIcon.svg";
import AnimationButton from "../../components/utils/AnimationButton";

const styles = { ...signUpstyles, ...legalInfostyles };

const LinkLegalInfo = ({ onLegalInfoSubmit, phone, nextStep }) => {
  const initialState = {
    userWhatsApp: phone,
    typePerson: "Física",
    ineFront: null,
    ineBack: null,
    clabe: "",
    confirmClabe: "",
    ownerBankAccount: "",
    bankName: "",
  };
  const submitFormData = true;
  const {
    handleFileChange,
    handleSubmit,
    errors,
    validatedFields,
    legalInfo,
    toggleTypePerson,
    handleInputChange,
    handleBlur,
    isLoading,
    isSuccess,
  } = useLegalInfo(
    onLegalInfoSubmit,
    initialState,
    validateLegalInfoLink,
    submitLegalInfo,
    submitFormData,
  );

  console.log(validatedFields);
  return (
    <form onSubmit={handleSubmit} method="post">
      {/*<h2 className={styles.resourcesHeader}>Información personal</h2>
      <hr className={styles.headRule} />*/}
      <h3 className={styles.resourcesSubTitle}>
        ¿Deseas registrarte como persona física o moral?
      </h3>
      <div className={styles.columnDocuments}>
        <div className={styles.contNavigator}>
          <div className={styles.columnSideBySide}>
            <button
              type="button"
              onClick={() => toggleTypePerson("Física")}
              className={`${styles.tabButton} ${
                legalInfo.typePerson === "Física" ? styles.activeTab : ""
              }`}
            >
              Persona Física
            </button>
            <button
              type="button"
              onClick={() => toggleTypePerson("Moral")}
              className={`${styles.tabButton} ${
                legalInfo.typePerson === "Moral" ? styles.activeTab : ""
              }`}
            >
              Persona Moral
            </button>
          </div>
        </div>
        <h2 className={styles.resourceSubHeader}>INE </h2>
        <em className={styles.emField}>
          (Si eres persona moral, deberá ser el representante legal)
        </em>
        <div className={styles.inputIdSideBySideLink}>
          <div className={styles.imageAndLinkContainerId}>
            <ImageUploadField
              id="ineFront"
              info={legalInfo}
              validated={validatedFields}
              errors={errors}
              handleFileChange={handleFileChange}
              icon={ineFrontIcon}
              label="Delantera"
              labelClassName="idUpload"
              containerClassName="filesColumnText"
              labelPosition="after"
              showFileName={false}
            />
          </div>
          <div className={styles.imageAndLinkContainerId}>
            <ImageUploadField
              id="ineBack"
              info={legalInfo}
              className={styles.inputClabeField}
              validated={validatedFields}
              errors={errors}
              handleFileChange={handleFileChange}
              icon={ineBackIcon}
              label="Trasera"
              labelClassName="idUpload"
              containerClassName="filesColumnText"
              labelPosition="after"
              showFileName={false}
            />
          </div>
        </div>
        <div className={styles.clabeFieldContainer}>
          <InputField
            em={true}
            label="Cuenta CLABE (¿En qué cuenta deseas recibir tus dépositos?)"
            type="text"
            name="clabe"
            className={styles.inputClabeField}
            placeholder="Ingresa tu cuenta CLABE"
            personalInfo={legalInfo}
            errors={errors}
            validatedFields={validatedFields}
            handleChange={handleInputChange}
            handleBlur={handleBlur}
          />
          <div className={styles.clabeInput}>
            <InputField
              label=""
              type="text"
              name="confirmClabe"
              placeholder="Confirma tu cuenta CLABE"
              personalInfo={legalInfo}
              errors={errors}
              validatedFields={validatedFields}
              handleChange={handleInputChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <InputField
          label="Titular de cuenta"
          type="text"
          name="ownerBankAccount"
          placeholder="Titular cuenta bancaria"
          personalInfo={legalInfo}
          errors={errors}
          validatedFields={validatedFields}
          handleChange={handleInputChange}
          handleBlur={handleBlur}
        />
        <div className={styles.formField}>
          <BankDropdown
            handleInputChange={handleInputChange}
            legalInfo={legalInfo}
            name="bankName"
          />
        </div>
      </div>
      <AnimationButton isLoading={isLoading} isSuccess={isSuccess}>
        Confirmar
      </AnimationButton>
    </form>
  );
};

export default LinkLegalInfo;
