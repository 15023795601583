import React, { useEffect } from 'react';
import styles from './PopUpMessage.module.css';

const PopUpMessage = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 1000); 
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    isVisible && (
      <div className={styles.popUpContainer}>
        <p className={styles.message}>{message}</p>
      </div>
    )
  );
};

export default PopUpMessage;
