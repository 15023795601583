import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/SettingsLinks.module.css';
import RightArrow from '../../../assets/icons/rightArrow.svg';
import BusinessIcon from '../../../assets/icons/business-icon.svg';
import HomeIcon from '../../../assets/icons/home-icon.svg';
import ShopIcon from '../../../assets/icons/shop-icon.svg';
import ProductsIcon from '../../../assets/icons/products-icon.svg';
import CategoriesIcon from '../../../assets/icons/categorys-icon.svg'
import BetterwareIcon from '../../../assets/icons/betterware-shop-icon.svg'

const iconMap = {
  'business-icon': BusinessIcon,
  'home-icon': HomeIcon,
  'shop-icon': ShopIcon,
  'products-icon': ProductsIcon,
  'categories-icon': CategoriesIcon,
  'betterware-icon': BetterwareIcon,
};

const SettingsLinks = ({ iconName, title, route }) => {
  const IconSrc = iconMap[iconName];
  const iconClassName = IconSrc ? styles.icon : `${styles.icon} ${styles.noIcon}`;

  return (
    <Link to={route} className={styles.button}>
      <div className={iconClassName}>
        {IconSrc ? <img src={IconSrc} alt={`${iconName} icon`} /> : null}
      </div>
      <div className={styles.textAndIcon}>
        <p className={styles.text}>{title}</p>
        <div className={styles.arrow}>
          <img src={RightArrow} alt="arrow" />
        </div>
      </div>
    </Link>
  );
};

export default SettingsLinks;