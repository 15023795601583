// shop/Shop.js
import { useParamsContext } from '../context/ParamsProvider';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import CategoryPage from './pages/CategoryPage';
import { CartProvider } from './contexts/CartContext';

function Shop() {
  const { phone, catalogParam } = useParamsContext();

  return (
    <CartProvider phone={phone} catalogParam={catalogParam}>
      <Routes>
        <Route path="/" element={<HomePage phone={phone} catalogParam={catalogParam} />} />
        <Route path="/product/:productIdOrId" element={<ProductPage phone={phone} catalogParam={catalogParam} />} />
        <Route path="/catalog/:catalog/category/:name" element={<CategoryPage phone={phone} catalogParam={catalogParam} />} />
        <Route path="/user/:phone/category/:name" element={<CategoryPage phone={phone} />} catalogParam={catalogParam}/>
      </Routes>
    </CartProvider>
  );
}

export default Shop;
