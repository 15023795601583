import { useState, useEffect } from 'react';
import { patchBasicBusinessInfo, patchBusinessInfo, catalogSuscription } from '../services/userUpdateData';
import { useUserContext } from '../../context/UserProvider';

const useUpdateInfo = (initialData, serviceType, phone, isProductServices = false) => {
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const tokenData = localStorage.getItem('accessToken');
  const { token } = JSON.parse(tokenData);

  const { fetchUserData } = useUserContext();

  useEffect(() => {
    setDisabled(JSON.stringify(formData) === JSON.stringify(initialData));
  }, [formData, initialData]);

  const logFormData = (updatedFormData) => {
    console.log('Current formData:', updatedFormData);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && (name === "businessLogo" || name === "businessHeroBanner")) {
      handleFileChange(name, files[0]);
    } else if (name.startsWith("socialMedia")) {
      handleSocialMediaChange(name, value);
    } else {
      handleGeneralInputChange(name, value);
    }
  };

  const handleSocialMediaChange = (fieldName, value) => {
    const platform = fieldName.split(".")[1];
    const updatedLinks = formData.socialMediaLinks.map((link) =>
      link.type === platform ? { ...link, link: value } : link
    );
    const updatedFormData = { ...formData, socialMediaLinks: updatedLinks };
    setFormData(updatedFormData);
    logFormData(updatedFormData); 
  };

  const handleGeneralInputChange = (fieldName, value) => {
    const updatedFormData = { ...formData, [fieldName]: value };
    setFormData(updatedFormData);
    logFormData(updatedFormData); 
  };

  const handleFileChange = (field, file) => {
    const updatedFormData = {
      ...formData,
      [field]: file || '', 
    };
    setFormData(updatedFormData);
    logFormData(updatedFormData);
  };

  const createFormData = (fields) => {
    const bodyFormData = new FormData();
    fields.forEach((field) => {
      const value = formData[field];
      if (value instanceof File) {
        console.log(`Appending file to FormData: ${field}`, value);
        bodyFormData.append(field, value);
      } else if (typeof value === 'object' && value !== null) {
        bodyFormData.append(field, JSON.stringify(value));
        console.log(`Appending data ${field}`, value);
      } else {
        bodyFormData.append(field, value);
        console.log(`Appending data ${field}`, value);
      }
    });
    return bodyFormData;
  };

  const updateBusinessInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      let response;
      
      if (isProductServices) {
        const basicInfoFields = ['businessLogo', 'businessName', 'businessDescription'];
        const advancedInfoFields = ['businessColor', 'businessHeroBanner', 'socialMediaLinks'];

        const basicInfoData = createFormData(basicInfoFields);
        const advancedInfoData = createFormData(advancedInfoFields);

        // First, update the basic business info
        response = await patchBasicBusinessInfo(token, basicInfoData);

        // Then, update the advanced business info
        await patchBusinessInfo(token, advancedInfoData);

      } else {
        const bodyFormData = createFormData(Object.keys(formData));
        response =
          serviceType === 'basic'
            ? await patchBasicBusinessInfo(token, bodyFormData)
            : await patchBusinessInfo(token, bodyFormData);
      }

      await fetchUserData(phone);

      setSuccess(true); 
      setTimeout(() => setSuccess(false), 1500); 

      return response;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const activateCatalogSubscription = async () => {
    try {
      setLoading(true);
      const response = await catalogSuscription(phone, "ACTIVATE");
      console.log('Catalog subscription activated:', response);
      return response;
    } catch (err) {
      console.error('Failed to activate catalog subscription:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const getLogoPreview = (field) => {
    const logo = formData[field];
    return logo && logo instanceof File
      ? URL.createObjectURL(logo)
      : logo;
  };

  const handleReset = () => {
    setFormData(initialData);
  };

  return {
    formData,
    loading,
    success,
    error,
    disabled,
    handleChange,
    updateBusinessInfo,
    activateCatalogSubscription, 
    handleReset,
    getLogoPreview, 
  };
};

export default useUpdateInfo;
