import React, { createContext, useContext, useState, useCallback } from 'react';
import { getUserData } from '../dashboard/services/userData';

const initialUserNameData = {
  preferedName: "",
  firstNameLastName: "",
  businessName: "",
  businessLogo: "",
  businessBanner: "",
  businessDescription: "",
  businessColor: "",
  socialMediaLinks: [],
  catalogs: []
};

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userNameData, setUserNameData] = useState(initialUserNameData);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const fetchUserData = useCallback(async (phone) => {
    setLoading(true);
    setErrors(null);
    try {
      const userData = await getUserData(phone);
      setUserNameData(userData);
    } catch (error) {
      setErrors('Failed to fetch user data');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider value={{ userNameData, loading, errors, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};
