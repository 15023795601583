import React from 'react';
import logo from '../../logo.svg';
import styles from '../styles/CsvFile.module.css';
import Upload from '../../assets/icons/uploadFile.svg';
import Download from '../../assets/icons/downloadFile.svg';

export const CsvFile = () => {
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.title}>
          <img src={logo} alt="Catalogo Web Logo" className={styles.logo} />
          <p>
            Subamos tus primeros productos, solo descarga la plantillla de productos y llenala, luego sube y mira como tus productos se suben automaticamente. 
          </p>
        </div>
        <div className={styles.inputContainer}>
          <p className={styles.subtitle}>Puedes subir archivo csv.</p>
            <button className={styles.buttons}>
                Descargar plantillla csv
                <img src={Download} alt="Download Icon" />
            </button>
            <button className={styles.buttons}>
                Subir documento
                <img src={Upload} alt="Upload Icon" />
            </button>
        </div>
        <div className={styles.continueContainer}>
          <button className={styles.continueButton}>Continuar</button>
        </div>
      </div>
    </div>
  );
};