import React from "react";
import createCatalogStyles from "../../assets/styles/CreateCatalog.module.css";
import signUpstyles from "../../SignUpForm.module.css";
import CatalogBetterware from "../../assets/Images/Betterware-Catalogue-Img.png";
import { useCreateCatalog } from "../../hooks/useCreateCatalog";
import AnimationButton from "../../components/utils/AnimationButton";

const styles = { ...createCatalogStyles, ...signUpstyles };

function CreateCatalog({ phone, onCatalogSelection, setBusinessType }) {
  const {
    handleSubmit,
    handleButtonClick,
    getPlaceholder,
    role,
    handleInputChange,
    isLoading,
  } = useCreateCatalog(phone, onCatalogSelection, setBusinessType);

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.container}>
        <section className={styles.logoSection}>
          <img
            name="betterware"
            src={CatalogBetterware}
            alt="Betterware"
            className={styles.selected}
          />
        </section>
        <p className={styles.question}>
          Estás por tener la mejor herramienta de ventas de Betterware
        </p>
        <p className={styles.questionq2}>
          (Actualmente solo disponible para vendedoras y distribuidoras ya
          afiliadas a Betterware)
        </p>
        <section className={styles.questionSection}>
          <h2>Ingresa tu código</h2>
          <div className={styles.buttons}>
            <button
              type="button"
              onClick={() => handleButtonClick("associate")}
              className={styles.buttonSelection}
            >
              Soy asociado
            </button>
            <button
              type="button"
              onClick={() => handleButtonClick("distributor")}
              className={styles.buttonSelection}
            >
              Soy distribuidor
            </button>
          </div>
          <div className={styles.inputSideBySideGroup}>
            <div className={styles.inputGroup}>
              <p className={styles.dynamicText}>Código postal</p>
              <input
                type="text"
                className={styles.input}
                placeholder="Código postal"
                name="businessZone"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <p className={styles.dynamicText}>{getPlaceholder()}</p>
              <input
                type="text"
                className={styles.input}
                placeholder={getPlaceholder()}
                name={
                  role === "associate"
                    ? "associateCode"
                    : role === "distributor"
                    ? "associateCode"
                    : ""
                }
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </section>
        <AnimationButton isLoading={isLoading}>Confirmar</AnimationButton>
      </div>
    </form>
  );
}

export default CreateCatalog;
