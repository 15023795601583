import React, { useState } from 'react';
import styles from '../../styles/UserProductServices.module.css';
import SettingsLinks from '../../components/navigation/SettingsLinks';
import FacebookIcon from '../../../assets/icons/facebook-icon.svg';
import InstagramIcon from '../../../assets/icons/instagram-icon.svg';
import TikTokIcon from '../../../assets/icons/tiktok-icon.svg'; 
import useUpdateInfo from '../../hooks/useUpdateInfo';
import RightArrow from '../../../assets/icons/rightArrow.svg';
import useSocialMediaLinks from '../../hooks/useSocialMediaLinks';
import usePopUpMessage from '../../hooks/usePopUpMessage';
import ContinueButton from '../../components/utils/ContinueButton';
import ChangeAlert from '../../components/ui/ChangeAlert';
import PopUpMessage from '../../components/ui/PopUpMessage';
import InputField from '../../components/utils/InputField';

const UserProductServices = ({ userNameData, phone }) => {
  const { popUp, handleSuccess, handleFailure, setPopUp } = usePopUpMessage();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isToggled, setToggled] = useState(userNameData.isCatalogEnable); 

  const initialState = {
    businessColor: userNameData.businessColor || "#000000",
    businessHeroBanner: userNameData.businessBanner || null,   
    socialMediaLinks: useSocialMediaLinks(userNameData.socialMediaLinks).socialMediaLinks,
    businessName: userNameData.businessName || '',
    businessDescription: userNameData.businessDescription || '',
    businessLogo: userNameData.businessLogo || '',        
  };

  const {
    formData,
    loading,
    success,
    disabled,
    handleChange,
    updateBusinessInfo,
    handleReset,
    getLogoPreview,
    activateCatalogSubscription
  } = useUpdateInfo(initialState, 'advanced', phone, true);

  const socialMediaIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    tiktok: TikTokIcon, 
  };

  const logoPreviewHeroBanner = getLogoPreview("businessHeroBanner");
  const logoPreviewLogo = getLogoPreview("businessLogo");

  const handleToggleChange = (e) => {
    if (!isToggled) {
      setPopupVisible(true);
    } else {
      setToggled(false);
    }
    e.target.checked = isToggled;
  };

  const handlePopupCancel = () => {
    setPopupVisible(false);
    setToggled(userNameData.isCatalogEnable); 
  };

  const handlePopupConfirm = async () => {
    setPopupVisible(false);
    try {
      await activateCatalogSubscription();
      setToggled(true);
    } catch (error) {
      console.error("Failed to activate subscription:", error);
      setToggled(userNameData.isCatalogEnable); 
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      updateBusinessInfo(e);
      handleSuccess();
    } catch {
      handleFailure();
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <ChangeAlert text="tu información" isVisible={!disabled} onDiscard={handleReset}/>
      <h1 className={styles.title}>Tienda de {userNameData.businessName}</h1>
      <article className={styles.logoContainer}>
        <label className={styles.placeholderLogo}>
          {logoPreviewLogo ? (
            <img
              src={logoPreviewLogo}
              alt="Business Logo"
              className={styles.logoImage}
            />
          ) : (
            <span className={styles.plusSign}>+</span>
          )}
          <input
            type="file"
            accept="image/*"
            id="businessLogo"
            name='businessLogo'
            onChange={handleChange}
            className={styles.fileInput}
          />
        </label>
        <p className={styles.businessName}>{userNameData.businessName}</p>
      </article>
      <div className={styles.subscriptionContainer}>
        <div className={styles.toggleStatus}>
          <p className={styles.text}>Activa tu tienda digital</p>
          <label className={styles.toggleSwitch}>
            <input 
              type="checkbox" 
              onChange={handleToggleChange} 
              checked={isToggled} 
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        <p className={styles.costText}>
          Costo mensual del servicio:
          <span>$149 MXN C/U</span>
        </p>
      </div>
      <p className={styles.subtitle}>Nombre de tu tienda o negocio</p>
      <InputField
        name="businessName"
        type="text"
        value={formData.businessName}
        onChange={handleChange}
        placeholder="Nombre tu tienda digital"
        noBorderOnDesktop={true}
      />
      <p className={styles.subtitle}>Descripcion breve de tu negocio</p>
      <InputField
        type="textarea"
        name="businessDescription"
        value={formData.businessDescription}
        onChange={handleChange}
        placeholder="Descripción de tu tienda digital"
        maxLength="100"
        noBorderOnDesktop={true}
      />
      <p className={styles.subtitle}>Categorias, porductos y servicios.</p>
      <article className={styles.settingsOptions}>
        <SettingsLinks
          title="Productos / servicios"
          route="/dashboard/products-services"
          iconName="products-icon"
        />
        <SettingsLinks
          title="Categorías"
          route="/dashboard/categorylist"
          iconName="categories-icon"
        />
      </article>

      <article className={styles.sectionContainer}>
        <p className={styles.subtitle}>URL de redes sociales</p>
        <div className={styles.socialMediaContainer}>
          {formData.socialMediaLinks.map((linkItem, index) => {
            const Icon = socialMediaIcons[linkItem.type];
            return (
              <div key={index} className={styles.socialMediaLink}>
                <img
                  src={Icon}
                  alt={`${linkItem.type} logo`}
                  className={styles.socialMediaLogo}
                />
                <input
                  className={styles.socialMediaInput}
                  type="text"
                  name={`socialMedia.${linkItem.type}`}
                  value={linkItem.link}
                  placeholder={`www.${linkItem.type}.com`} 
                  onChange={handleChange}
                />
              </div>
            );
          })}
        </div>
      </article>

      <article className={styles.sectionContainer}>
        <p className={styles.subtitle}>Imagen de portada</p>
        <div className={styles.imageUploadContainer}>
          <div className={styles.imageUploadInput}>
            {logoPreviewHeroBanner ? (
              <img
                src={logoPreviewHeroBanner}
                alt="Business Banner"
                className={styles.bannerImage}
              />
            ) : (
              <span className={styles.plusSymbol}>+</span>
            )}
            <input
              type="file"
              accept="image/*"
              name='businessHeroBanner'
              onChange={handleChange}
              title="image-input"
              placeholder=""
            />
          </div>
        </div>
      </article>

      <article className={styles.sectionContainer}>
        <p className={styles.subtitle}>Color de la marca</p>
        <div
          className={styles.colorPickerContainer}
          onClick={() => document.getElementById('colorPicker').click()}
        >
          <div
            className={styles.colorCircle}
            style={{ backgroundColor: formData.businessColor }}
          ></div>
          <input
            type="color"
            name="businessColor"
            id="colorPicker"
            value={formData.businessColor}
            onChange={handleChange}
            className={styles.colorPicker}
          />
          <div className={styles.colorPickerLabel}>Brand Color</div>
          <div className={styles.arrow}>
            <img src={RightArrow} alt="arrow" />
          </div>
        </div>
      </article>
      <ContinueButton type="submit" isLoading={loading} isSuccess={success} disabled={disabled}/>
      <PopUpMessage
        title={popUp.title}
        message={popUp.message}
        isVisible={popUp.isVisible}
        onClose={() => setPopUp({ ...popUp, isVisible: false })}
      />
      {isPopupVisible && (
        <div className={styles.popupContainer}>
          <div className={styles.popupContent}>
            <p>Activa tu tienda digital y disfruta de 30 días gratis. Después, solo $189MXN al mes</p>
            <div className={styles.popupActions}>
              <button className={styles.cancelButton} onClick={handlePopupCancel}>Cancelar</button>
              <button className={styles.okButton} onClick={handlePopupConfirm}>OK</button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default UserProductServices;
