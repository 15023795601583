import React from 'react'
import styles from '../../styles/SearchBar.module.css'

export const SearchBar = ({ searchTerm, setSearchTerm }) => {

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
    
  return (
    <>      
        <input
            type="text"
            className={styles.searchInput}
            placeholder="Buscar"
            value={searchTerm}
            onChange={handleSearch}
        />
    </>
  )
}
