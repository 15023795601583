import styles from '../../styles/UserBussines.module.css';
import desktopStyles from '../../styles/DesktopStyles.module.css';
import InputField from '../../components/utils/InputField';
import useUpdateInfo from '../../hooks/useUpdateInfo';
import ContinueButton from '../../components/utils/ContinueButton';
import ChangeAlert from '../../components/ui/ChangeAlert';
import PopUpMessage from '../../components/ui/PopUpMessage';
import usePopUpMessage from '../../hooks/usePopUpMessage';

const UserBussines = ({ userNameData, phone }) => {
  const { popUp, handleSuccess, handleFailure, setPopUp } = usePopUpMessage();

  const initialState = {
    businessName: userNameData.businessName || '',
    businessDescription: userNameData.businessDescription || '',
    businessLogo: userNameData.businessLogo || '',
  };

  const {
    formData,
    loading,
    success,
    disabled,
    error,
    handleChange,
    updateBusinessInfo,
    handleReset
  } = useUpdateInfo(initialState, 'basic', phone);


  const logoPreview = formData.businessLogo && formData.businessLogo instanceof File
    ? URL.createObjectURL(formData.businessLogo)
    : formData.businessLogo;

    const handleSubmit = (e) => {
      e.preventDefault();
      try {
        updateBusinessInfo(e);
        handleSuccess('¡Tu información de negocio se guardo correctamente!.');
      } catch {
        handleFailure('¡Hubo un problema al intentar actualizar tu información!.Intenta más tarde.');
      }
    };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      {loading && <p>Updating...</p>}
      {error && <p>Error: {error}</p>}

      <section className={styles.mobileSection}>
        <article className={styles.logoContainer}>
          <label className={styles.placeholderLogo}>
            {logoPreview ? (
              <img
                src={logoPreview}
                alt="Business Logo"
                className={styles.logoImage}
              />
            ) : (
              <span className={styles.plusSign}>+</span>
            )}
            <input
              type="file"
              accept="image/*"
              id="businessLogo"
              name='businessLogo'
              onChange={handleChange}
              className={styles.fileInput}
            />
          </label>
          {logoPreview? (
          <p className={styles.logoText}></p>
           ) : (
            <p className={styles.logoText}>Logo</p>
          )}
          <p className={styles.businessName}>{formData.businessName}</p>
        </article>
        <article>
          <InputField
            label="Nombre de tu tienda o negocio"
            name="businessName"
            type="text"
            value={formData.businessName}
            onChange={handleChange}
            placeholder="Como se llama tu tienda digital"
          />
        </article>
        <article className={styles.sectionContainer}>
          <InputField
            label="Descripcion breve de tu negocio"
            type="textarea"
            name="businessDescription"
            value={formData.businessDescription}
            onChange={handleChange}
            placeholder="Descripción de tu negocio, usualmente usado en bio y listados"
            maxLength="55"
          />
        </article>
      </section>

      {/* DESKTOP SECTION  */}
      <section className={desktopStyles.desktopSection}>
      <ChangeAlert text="tu información" isVisible={!disabled} onDiscard={handleReset}/>
        <h3 className={styles.title}>Mi negocio</h3>
        <section className={desktopStyles.section}>
          <h3 className={desktopStyles.subtitle}>Logo</h3>
          <p>Se usa en links compartidos y home de tu catalogo</p>
          <div className={desktopStyles.logoDesktop}>
            <label className={styles.placeholderLogo}>
              {logoPreview ? (
                <img
                  src={logoPreview}
                  alt="businessLogo"
                  className={styles.logoImage}
                />
              ) : (
                <span className={styles.plusSign}>+</span>
              )}
              <input
                type="file"
                accept="image/*"
                id="File businessLogo"
                name='businessLogo'
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            <div className={desktopStyles.logoText2}>
              {logoPreview ? (
                <p>Logo predeterminado</p>
              ) : (
                <>
                  <p>Agregar logo predeterminado</p>
                  <small>
                    WEBP, PNG o JPG. Ancho recomendado: 512 pixeles mínimo
                  </small>
                </>
              )}
            </div>
          </div>
        </section>

        <section className={desktopStyles.section}>
          <h3 className={desktopStyles.title}>Información del negocio</h3>
          <InputField
            label="Nombre"
            name="businessName"
            type="text"
            value={formData.businessName}
            onChange={handleChange}
            placeholder="Como se llama tu tienda digital"
          />
          <InputField
            label="Descripción de tu negocio"
            name="businessDescription"
            type="textarea"
            value={formData.businessDescription}
            onChange={handleChange}
            placeholder="Descripción de tu negocio, usualmente usado en bio y listados"
            maxLength="55"
          />
        </section>
      </section>
      <ContinueButton type="submit" isLoading={loading} isSuccess={success} disabled={disabled}/>
      <PopUpMessage
        title={popUp.title}
        message={popUp.message}
        isVisible={popUp.isVisible}
        onClose={() => setPopUp({ ...popUp, isVisible: false })}
      />
      </form>
  );
};

export default UserBussines;