import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './InputField.module.css';

const DropdownInputField = ({
  name= '',
  label= '',
  value= '',
  onChange= () => {},
  categories= [],
  options= [],
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setDropdownVisible(false);
    }, 200); 
  };

  const handleSelectItem = (itemName) => {
    setInputValue(itemName);
    onChange({ target: { name, value: itemName } });
    setDropdownVisible(false);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  return (
    <div className={styles.inputField}>
      <span className={styles.labelText}>{label}</span>
      <input
        type="text"
        name={name}
        placeholder={label}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        className={styles.input}
        id={name}
      />
      {isDropdownVisible && (
        <ul className={styles.dropdownList}>
          {(categories.length > 0 ? categories : options).map((item) => {
            const displayText = typeof item === 'object' ? item.categoryName : item;
            return (
              <li
                key={displayText}
                className={styles.dropdownItem}
                onClick={() => handleSelectItem(displayText)}
              >
                {displayText}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

DropdownInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onCreateCategory: PropTypes.func,
};

export default DropdownInputField;
