import React from "react";
import { Link } from "react-router-dom";
import styles from "./CategoryList.module.css";

const CategoryList = ({ categories, phone, catalogParam, activeCategory }) => {
  const filteredCategories = categories.filter(category => 
    !/sin categorizar|uncategorized|sin categoria|feed/i.test(category)
  );

  const reorderedCategories = activeCategory
    ? [activeCategory, ...filteredCategories.filter(category => category !== activeCategory)]
    : filteredCategories;

  return (
    <div className={styles.categoryList}>
      {reorderedCategories.map((category) => (
        
        <Link
          key={category}
          to={
            catalogParam
              ? `/shop/catalog/${catalogParam}/category/${category}`
              : `/shop/user/${phone}/category/${category}`
          }
        >
          <button
            className={`${styles.categoryButton} ${activeCategory === category ? styles.active : ''}`}
          >
            {category}
          </button>
        </Link>
      ))}
    </div>
  );
};

export default CategoryList;
