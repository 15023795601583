const apiUrl = process.env.REACT_APP_AIDA_CHANNELS_API;

export const subtmitLogin = async (phone, password) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/aida/user/login?username=${phone}&password=${password}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'text/plain',
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error("Failed to submit file data:", error);
    }
  };

  export const forgotPassword = async (phone) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/dashboard/user/pass/${phone}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'text/plain',
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error("Failed to submit file data:", error);
    }
  };
