import React from "react";
import styles from "../../assets/styles/CreateOrSubscribe.module.css";
import CelImage from "../../assets/Images/Phone.svg";
import AnimationButton from "../../components/utils/AnimationButton";

const CreateOrSubscribe = ({ onSubscriptionSubmit }) => {
  return (
    <div className={styles.container}>
      <section className={styles.catalogContent}>
        <aside>
          <h3>Tu Tienda Digital Betterware</h3>
          <p className={styles.listTitle}>Beneficios:</p>
          <ul>
            <li>Tienda digital personalizada.</li>
            <li>Acepta todas las formas de pago.</li>
            <li>Tu asistente de ventas desde WhatsApp.</li>
          </ul>
          <p>
            Registrarte hoy y tu primer mes es gratis a partir de ahí tendra un
            costo de $99 pesos al mes.
          </p>
        </aside>
        <img src={CelImage} alt="imagen celular" />
      </section>
      <AnimationButton onClick={() => onSubscriptionSubmit()}>
        Confirmar
      </AnimationButton>
    </div>
  );
};

export default CreateOrSubscribe;
