import React from 'react'
import styles from './ChangeAlert.module.css'
import alertIcon from '../../../assets/icons/alert-icon.svg'

const ChangeAlert = ({ text, onDiscard, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className={styles.alertContainer}>
      <div className={styles.textAndIcon}>
        <img src={alertIcon} alt='Alert icon'/>
        <p>No haz guardado {text}</p>
      </div>
      <div className={styles.buttonsContainer}>
        <button
          className={styles.discardButton}
          type='button'
          onClick={onDiscard}
        >
          Descartar
        </button>
        <button
          className={styles.saveButton}
          type='submit'
        >
          Guardar
        </button>
      </div>
    </div>
  )
}

export default ChangeAlert
