import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/ReturnButton.module.css';


const ReturnButton = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className={styles.returnButton}>
      <span className={styles.arrow}></span>
      Regresar
    </button>
  );
};

export default ReturnButton;