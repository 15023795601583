import React from 'react';
import styles from './StatusToggle.module.css'; 

const StatusToggle = ({ checked, onChange }) => {
  const handleToggleChange = (event) => {
    const newStatus = event.target.checked ? 'true' : 'false';
    onChange({ target: { name: 'status', value: newStatus } });
  };

  return (
    <div className={styles.statusToggle}>
      <span className={styles.statusLabel}>{checked === 'true' ? 'Activo' : 'Inactivo'}</span>
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={checked === 'true'}
          onChange={handleToggleChange}
        />
        <span className={styles.slider}></span>
      </label>
    </div>
  );
};

export default StatusToggle;
