import React from 'react'
import ContentLoader from 'react-content-loader'
import HeaderLoader from '../HomePage/HeaderLoader'

export const CategoryLoader = ({ props }) => {
  return (
    <div>
        <ContentLoader viewBox="0 0 462 160" height={200} width="100%" {...props} backgroundColor='#E8EAED' foregroundColor='#f5f5f5'>
            <rect x="70" y="16" rx="5" ry="5" width="150" height="15" />
            <rect x="26" y="15" rx="0" ry="0" width="15" height="15" />
            <rect x="85%" y="10" rx="10" ry="10" width="25" height="25" />
            <rect x="25" y="60" rx="10" ry="10" width="400" height="35" />
            <rect x="25" y="125" rx="5" ry="5" width="50" height="15" />
            <rect x="100" y="125" rx="5" ry="5" width="50" height="15" />
            <rect x="175" y="125" rx="5" ry="5" width="50" height="15" />
            <rect x="250" y="125" rx="5" ry="5" width="50" height="15" />
            <rect x="325" y="125" rx="5" ry="5" width="50" height="15" />
            <rect x="400" y="125" rx="5" ry="5" width="50" height="15" />
            <rect x="0" y="155" rx="0" ry="0" width="100%" height="1" />
        </ContentLoader>
        <HeaderLoader showHeading={false}/>
    </div>
  )
}
