import { useState, useEffect, useCallback } from 'react';
import { validateImageFormat } from '../assets/utils/validators';
import { validateBusinessInfo } from '../assets/utils/UtiltiesFunctions';

const useBusinessInfo = ( onBusinessInfoSubmit, initialState, submit) => {
  
    const [businessInfo, setBusinessInfo] = useState(initialState);
    console.log("Business Info:", businessInfo);
    const [errors, setErrors] = useState({});
    const [validatedFields, setValidatedFields] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [imagePreviews, setImagePreviews] = useState({
      businessLogo: "",
      businessHeroBanner: "",
    });

    const handleCheckboxChange = useCallback((e) => {
        const { name, checked } = e.target;
        setBusinessInfo(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    }, []); 

    useEffect(() => {
      return () => {
        if (imagePreviews.businessLogo)
          URL.revokeObjectURL(imagePreviews.businessLogo);
        if (imagePreviews.businessHeroBanner)
          URL.revokeObjectURL(imagePreviews.businessHeroBanner);
      };
    }, [imagePreviews]);
    
    const handleChange = (e) => {
      const { name, value, files } = e.target;
  
      if (files && (name === "businessLogo" || name === "businessHeroBanner")) {
          handleFileChange(name, files[0]);
      } else if (name.startsWith("socialMedia")) {
          handleSocialMediaChange(name, value);
      } else {
          handleGeneralInputChange(name, value);
      }
    };
    
    const handleFileChange = (fieldName, file) => {
      if (!file) {
          setImagePreviews(prev => ({ ...prev, [fieldName]: "" }));
          clearError(fieldName);
          return;
      }
  
      if (!validateImageFormat(file.name)) {
          setErrors(prev => ({ ...prev, [fieldName]: "Invalid image format" }));
          setValidatedFields(prev => ({ ...prev, [fieldName]: false }));
          return;
      }
  
      setBusinessInfo(prev => ({ ...prev, [fieldName]: file }));
      const previewUrl = URL.createObjectURL(file);
      setImagePreviews(prev => ({ ...prev, [fieldName]: previewUrl }));
      clearError(fieldName);
  };
    
    const handleSocialMediaChange = (fieldName, value) => {
        const platform = fieldName.split(".")[1];
        const updatedLinks = businessInfo.socialMediaLinks.map(link =>
            link.type === platform ? { ...link, link: value } : link
        );
        setBusinessInfo(prev => ({ ...prev, socialMediaLinks: updatedLinks }));
        validateAndSetErrors(fieldName, updatedLinks);
    };
    
    const handleGeneralInputChange = (fieldName, value) => {
        setBusinessInfo(prev => ({ ...prev, [fieldName]: value }));
        if (value.trim()) {
            clearError(fieldName);
            setValidatedFields(prev => ({ ...prev, [fieldName]: true }));
        } else {
            setErrors(prev => ({ ...prev, [fieldName]: "This field cannot be empty." }));
            setValidatedFields(prev => ({ ...prev, [fieldName]: false }));
        }
    };
    
    const clearError = (fieldName) => {
        setErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[fieldName];
            return newErrors;
        });
        setValidatedFields(prev => ({ ...prev, [fieldName]: true }));
    };
    
    const validateAndSetErrors = (fieldName, value) => {
      const validationResults = validateBusinessInfo({ ...businessInfo, [fieldName]: value });
      setErrors(validationResults);
  
      Object.keys(validationResults).forEach(key => {
          setValidatedFields(prev => ({ ...prev, [key]: !validationResults[key] }));
      });
  };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (validateAndSetErrorsSubmit()) {
            try {
                const formData = createFormData();
                const submittedData = await submit(formData);
                setIsLoading(false);
                setIsSuccess(true);
                setTimeout(() => {
                    onBusinessInfoSubmit(submittedData); 
                    setIsSuccess(false);
                }, 1500)
            } catch (error) {
                console.error("Failed to submit business info:", error);
                setIsLoading(false);
            }
        }
    };
  
  const validateAndSetErrorsSubmit = () => {
      const validationErrors = validateBusinessInfo(businessInfo);
      if (Object.keys(validationErrors).length === 0) {
          return true;
      } else {
          setErrors(validationErrors);
          console.log("Validation errors:", validationErrors);
          return false;
      }
  };
  
  const createFormData = () => {
    const formData = new FormData();
    Object.entries(businessInfo).forEach(([key, value]) => {
        if (value instanceof File) {
            console.log(`Appending file to FormData: ${key}`, value);
            formData.append(key, value);
        } else if (typeof value === 'object' && value !== null) {
            formData.append(key, JSON.stringify(value));
        } else {
            formData.append(key, value);
        }
    });
    return formData;
  };
  
    return {
        businessInfo, 
        setBusinessInfo, 
        errors, 
        validatedFields, 
        imagePreviews, 
        setImagePreviews, 
        handleChange, 
        handleSubmit, 
        isLoading,
        handleCheckboxChange,
        isSuccess 
    }
}

export default useBusinessInfo;