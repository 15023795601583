// validators.js

// Email validation
export const validateEmail = (email) => {
  // Updated regex pattern for better email validation
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// WhatsApp number validation
export const validateWhatsAppNumber = (number) => {
  const regex = /^\d{1,10}$/;
  return regex.test(number);
};

// Register code validation
export const validateRegisterCode = (code) => {
  return code === "" || (code.length >= 5 && code.length <= 10);
};

// Password validation
export const validatePassword = (password) => {
  return password.length >= 6;
};

// Password confirmation
export const passwordsMatch = (password, confirmPassword) => {
  return password === confirmPassword;
};

// Member Number validation
export const validateMemberNumber = (code) => {
  return code === "" || (code.length >= 5 && code.length <= 10);
};
// Social links validation
export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

// Image format validation (PNG or JPG)
export const validateImageFormat = (
  fileName,
  allowedFormats = ["jpg", "jpeg", "png"]
) => {
  const regex = new RegExp(`\\.(${allowedFormats.join("|")})$`, "i");
  return regex.test(fileName);
};

// CSV format validation
export const isCsvFile = (fileName) => {
  return /\.csv$/i.test(fileName);
};

export const validateClabe = (clabe) => {
  const sanitizedClabe = clabe.replace(/\s+/g, '');
  const regex = /^\d{18}$/;
  return regex.test(sanitizedClabe);
};

export const validateNonEmptyString = (value) => {
  if (value === undefined || value === null || value.trim() === '') {
    return false;
  }
  return true;
};

export const validateCsfFormat = (fileName) => {
  return validateImageFormat(fileName, ["pdf"]); 
};