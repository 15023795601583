import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProductGrid from "../components/ProductGrid/ProductGrid";
import CartToggle from "../components/Cart/CartToggle";
import { ReactComponent as ReturnIcon } from "../../assets/icons/return-arrow.svg";
import { useCategoryProducts } from "../hooks/pages_hooks/useCategoryProducts";
import styles from "../styles/CategoryPage.module.css";
import CategoryList from "../components/CategorySection/CategoryList";
import { useHomePageData } from "../hooks/pages_hooks/useHomePageData";
import ProductSearchComponent from "../components/Search/Search";
import { CategoryLoader } from "../components/Loaders/CategoryPage/CategoryLoader";
import Lottie from 'lottie-react';
import spinnerAnimation from '../../assets/animations/ScreenLoader.json';

function CategoryPage({ phone, catalogParam }) {
  const { name, catalog } = useParams();
  const navigate = useNavigate();
  const { products, loading, error, setPage, hasMore, resetProducts } = useCategoryProducts(name, catalog, phone);
  const { categories, categoryProducts } = useHomePageData(phone, catalogParam);

  useEffect(() => {
    resetProducts();
  }, [name, resetProducts]);

  const handleLoadMore = () => {
    if (hasMore && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  };

  if (loading && products.length === 0) return <CategoryLoader />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className={styles.categoryGrid}>
      <div className={styles.categoryContainer}>
        <div className={styles.categoryHeader}>
          <div className={styles.headerContent}>
            <button onClick={() => navigate(-1)} className={styles.returnButton}>
              <ReturnIcon />
            </button>
            {name.toLowerCase() !== "feed" && <h1>{name}</h1>}
            </div>
          <CartToggle phone={phone} catalogParam={catalogParam}/>
        </div>
        <ProductSearchComponent categories={categories} categoryProducts={categoryProducts} />
        <div className={styles.searchComponents}>
          <CategoryList categories={categories} phone={phone} catalogParam={catalogParam} activeCategory={name} />
        </div>
      </div>
      <section className={styles.productCard}>
        <hr />
        <div className={styles.products}>
          <ProductGrid products={products} onLoadMore={handleLoadMore} catalogParam={catalogParam}/>
        </div>
      </section>
      {loading && (
        <div className={styles.loadingSpinner}>
          <Lottie animationData={spinnerAnimation} style={{ width: 30, height: 30 }} />
        </div>
      )}
    </div>
  );
}

export default CategoryPage;