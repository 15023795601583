import React from 'react'
import { ReactComponent as ReturnIcon } from "../../assets/icons/return-arrow.svg";
import styles from "../../assets/styles/ReturnButton.module.css"

export const ReturnButton = ({ prevStep }) => {
  return (
    <button onClick={() => prevStep()} className={styles.returnButton}>
    <ReturnIcon />
  </button>
  )
}
